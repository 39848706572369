import { Hub } from "aws-amplify";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Redirect, Route, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import SignInPage from "@src/pages/SignIn";
import PasswordRegistrationPage from "@pages/PasswordRegistration";
import AuthenticationService from "@services/Authentication";
import store from "@store";
import {
  initialize,
  login,
  logout,
  notifyTimeout,
  // notifyDisabledUser,
} from "@store/modules/auth";
import ForgotPassword from "@pages/ForgotPassword";
import PasswordCode from "@pages/PasswordCode";
import SignUpPage from "@pages/SignUp";

import "react-toastify/dist/ReactToastify.min.css";
import { useQuery } from "./utils/useQuery";
import ProfilePage from "./pages/Profile";
import UserUtil from "@utils/user";
import ConfigurationPage from "./pages/Configuration";
import UpdatePassword from "./pages/UpdatePassword";
import ResultsPage from "./pages/Results";

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const initialized = useSelector((state) => state.auth.initialized);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const cognitoUser = useSelector((state) => state.auth.cognitoUser);

  useEffect(() => {
    let username = query.get("username");
    let code = query.get("code");

    if (username && code) {
      AuthenticationService.confirmSignUp(username, code).then(() => {
        toast.success("Email verified!");
        AuthenticationService.logout();
      });
    }
  }, [query]);

  useEffect(() => {
    function executeLogout() {
      dispatch(logout());
      dispatch(initialize());
    }

    function executeOAuthLogin(data) {
      dispatch(login(UserUtil.mapFromOAuth2User(data)));
      dispatch(initialize());
    }

    function initializeLoggedOut() {
      dispatch(logout());
      dispatch(initialize());
    }

    function initializeLoggedIn(cognitoUser) {
      dispatch(login(UserUtil.mapFromCognitoUser(cognitoUser)));
      dispatch(initialize());
    }

    function logoutByTimeout() {
      toast.warn("You user session has expired. Please Sign In again.");
      store.dispatch(notifyTimeout());
      store.dispatch(logout());
    }

    function initializeUserAuthentication() {
      if (!initialized) {
        Hub.listen("auth", ({ payload: { event, data } }) => {
          switch (event) {
            case "signIn":
              executeOAuthLogin(data);
              break;
            case "signOut":
              executeLogout();
              break;
            default:
              break;
          }
        });

        AuthenticationService.currentAuthenticatedUser().then(
          (userLoggedIn) => {
            if (userLoggedIn) {
              initializeLoggedIn(userLoggedIn);
            } else {
              initializeLoggedOut();
            }
          }
        );
      }
    }

    function checkUserAuthorization() {
      const { initialized, authenticated } = store.getState().auth;
      if (!initialized || !authenticated) {
        return;
      }
      AuthenticationService.currentAuthenticatedUser().then((userLoggedIn) => {
        if (!userLoggedIn) {
          logoutByTimeout();
        }
      });
    }

    initializeUserAuthentication();

    const unlistenHistory = history.listen((location, action) => {
      if (action === "PUSH") {
        checkUserAuthorization();
      }
    });
    return () => {
      unlistenHistory();
    };
  }, [history, dispatch, initialized, authenticated]);

  return (
    <React.Fragment>
      {initialized && !authenticated && (
        <Switch>
          <Route path="/signin" component={SignInPage} />
          {cognitoUser ? (
            <Route
              path="/cadastro-senha"
              component={PasswordRegistrationPage}
            />
          ) : (
            <Redirect from="/cadastro-senha" to="/signin" />
          )}
          <Route path="/signup">
            <SignUpPage />
          </Route>

          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>

          <Route path="/change-password">
            <PasswordCode />
          </Route>
          <Route>
            <Redirect to="/signin" />
          </Route>
        </Switch>
      )}
      {initialized && authenticated && (
        <Switch>
          <Route path="/configuration" component={ConfigurationPage} />
          <Route path="/results" component={ResultsPage} />
          <Route path="/profile" component={ProfilePage} />
          <Route path="/update-password" component={UpdatePassword} />
          <Route>
            <Redirect to="/configuration" />
          </Route>
        </Switch>
      )}
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ minWidth: "30vw", maxWidth: "50vw", fontSize: "17px" }}
      />
    </React.Fragment>
  );
}

export default App;
