import * as Yup from "yup";

export const passwordRule = () => {
  return Yup.string()
    .required("Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^\$\*\.\[\]\(\)\?\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`])[A-Za-z\d\^\$\*\.\[\]\(\)\?\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`]{8,}$/g, // eslint-disable-line no-useless-escape
      "A senha deve conter no mínimo 8 carateres, letra minúscula e maiúscula, número e caracter especial: ^$*.[]()?\"!@#%&/,><':;|_~`"
    );
};

export const confirmationPasswordRule = (passwordElement) => {
  return Yup.string()
    .required("Required")
    .oneOf(
      [Yup.ref(passwordElement), null],
      "Confirmation password does not match"
    );
};

const validation = {
  passwordRule,
  confirmationPasswordRule,
};

export default validation;
