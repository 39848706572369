const settings = {
  awsAmplify: {
    Auth: {
      identityPoolId: "us-east-1:45e14cbc-6156-48fd-9ebc-3bb69afdc417",
      region: "us-east-1",
      userPoolId: "us-east-1_KQWH7IjbO",
      userPoolWebClientId: "6n1g9scsdk6hmbml1fg1v4f6s6",
      oauth: {
        domain: "auth.samplifier.app",
        scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin",
        ],
        redirectSignIn: `${window.location.origin}/signin`,
        redirectSignOut: `${window.location.origin}/signin`,
        responseType: "code",
      },
    },
    API: {
      endpoints: [
        {
          name: "admin-api",
          endpoint: "https://api.samplifier.app",
        },
        {
          name: "web-api",
          endpoint: "https://api.samplifier.app",
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: "samplifier-backend-api-stetcalccalcbucket3985382cba00be2340b3",
        region: "us-east-1",
        customPrefix: {
          public: "",
        },
      },
    },
  },
  baseUrl: "https://www.samplifier.app/",
};

export default settings;
