import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  baseStyle: {},
  mainButtonOption: {
    background: "#0D4A63",
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    color: "#FFFFFF",
  },
  secondaryButtonOption: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmationDialog(props) {
  const classes = useStyles();

  function handleClose() {
    props.onClose();
  }

  function handleAgree() {
    props.onAgree();
  }

  function handleDisagree() {
    props.onDisagree();
  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      {props.title && (
        <DialogTitle id="confirmation-dialog-title">{props.title}</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {props.message}
          {props.needApproval ? (
            <>
              (
              <a href="https://my.ncqa.org/" target="_blank" rel="noreferrer">
                https://my.ncqa.org/
              </a>
              ).
            </>
          ) : (
            ""
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDisagree}
          variant="outlined"
          className={classes.secondaryButtonOption}
        >
          {props.disagreeButtonLabel}
        </Button>
        <Button
          onClick={handleAgree}
          variant="contained"
          className={classes.mainButtonOption}
        >
          {props.agreeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  agreeButtonLabel: PropTypes.string,
  disagreeButtonLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onAgree: PropTypes.func.isRequired,
  onDisagree: PropTypes.func.isRequired,
};

ConfirmationDialog.defaultProps = {
  open: false,
  title: "",
  message: "",
  agreeButtonLabel: "Cancelar",
  disagreeButtonLabel: "Continuar",
  onClose: () => {},
  onAgree: () => {},
  onDisagree: () => {},
};

export default ConfirmationDialog;
