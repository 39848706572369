import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import * as Yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import LockIcon from "@material-ui/icons/LockOutlined";
import { InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AuthenticationService from "@services/Authentication";
import AuthTemplate from "@templates/Auth";
import { confirmationPasswordRule } from "@src/utils/validation";
import MainFrame from "@src/components/MainFrame";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  frameForm: {
    boxSizing: "border-box",
    padding: "32px",
    background: "#FFFFFF",
    width: "100%",
    minWidth: "450px",
    height: "100%",
    borderRadius: "8.327px",
    boxShadow: "0px 35px 33.308px -15px rgba(83, 129, 248, 0.24)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "2",
    "@media all and (min-width: 768px)": {
      padding: "10px",
    },
  },
  paper: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    "@media all and (min-width: 768px)": {
      width: "320px",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#660099",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    backgroundColor: "#0D4A63",
    margin: theme.spacing(2, 0, 2),
  },
  buttonText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    color: "#FFFFFF",
  },
  login: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: theme.spacing(2),
  },
  loginLink: {
    textDecoration: "none",
    color: "rgb(0, 0, 0)",
  },
  title: {
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "26.5px",
    lineHeight: "40px",
    fontStyle: "normal",
    color: "#0D4A63",
    alignSelf: "center",
    marginBottom: "10px",
    marginTop: "20px",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    fontStyle: "normal",
    color: "#0D4A63",
    alignSelf: "center",
    marginBottom: "10px",
  },
  backMenu: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  backMenuLink: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    gridTemplateColumns: "1fr 2fr",
    gap: "5px",
    textDecoration: "none",
    color: "#D53127",
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "21px",
    fontStyle: "normal",
  },
}));

function UpdatePassword(props) {
  const classes = useStyles();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  let passwordConfirmationInputRef = null;

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    passwordConfirmation: "",
  };

  function handleSubmit(values, resetForm) {
    if (loading) {
      return;
    }

    setLoading(true);

    AuthenticationService.updatePassword(
      values.currentPassword,
      values.newPassword
    )
      .then(() => {
        toast.success("Your password has been changed.");
        resetForm(initialValues);
        history.push("/profile");

        setLoading(false);
      })
      .catch((error) => {
        if (error.code === `InvalidParameterException`) {
          toast.error(
            "The new password must contain at least 8 characters, including at least one upper case letter, one lower case letter, one number and one special character."
          );
        } else {
          toast.error(error.message);
        }
        setLoading(false);
      });
  }

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Required"),
    newPassword: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/,
        "Required a minimum of 8 characters, including at least one capital letter, one number and one symbol."
      )
      .when(["currentPassword"], (currentPassword, schema) => {
        return schema.not(
          [currentPassword],
          "New password must be different from the current one"
        );
      }),
    passwordConfirmation: confirmationPasswordRule("newPassword"),
  });

  return (
    <AuthTemplate>
      <MainFrame justifyLogo="center">
        <div className={classes.frameForm}>
          <div className={classes.backMenu}>
            <Link to="/profile" className={classes.backMenuLink}>
              <ArrowBack />
              Back
            </Link>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            isInitialValid={false}
          >
            {({
              errors,
              touched,
              isValid,
              values,
              handleChange,
              handleBlur,
              resetForm,
            }) => (
              <div className={classes.paper}>
                <Typography className={classes.title}>
                  Update your password
                </Typography>

                {/* confirm current password input */}
                <TextField
                  value={values.currentPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    touched.currentPassword ? errors.currentPassword : ""
                  }
                  error={
                    touched.currentPassword && Boolean(errors.currentPassword)
                  }
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="currentPassword"
                  label="Current password"
                  type={showCurrentPassword ? "text" : "password"}
                  id="currentPassword"
                  autoComplete="current-password"
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && passwordConfirmationInputRef) {
                      passwordConfirmationInputRef.focus();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowCurrentPassword(!showCurrentPassword);
                          }}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showCurrentPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {/* new password input */}
                <TextField
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.newPassword ? errors.newPassword : ""}
                  error={touched.newPassword && Boolean(errors.newPassword)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="New password"
                  type={showNewPassword ? "text" : "password"}
                  id="newPassword"
                  autoComplete="new-password"
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && passwordConfirmationInputRef) {
                      passwordConfirmationInputRef.focus();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowNewPassword(!showNewPassword);
                          }}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* end password input */}

                {/* password confirmation input */}
                <TextField
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    touched.passwordConfirmation
                      ? errors.passwordConfirmation
                      : ""
                  }
                  error={
                    touched.passwordConfirmation &&
                    Boolean(errors.passwordConfirmation)
                  }
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="passwordConfirmation"
                  label="Confirm new password"
                  type={showPasswordConfirmation ? "text" : "password"}
                  autoComplete="new-password"
                  inputRef={(element) => {
                    passwordConfirmationInputRef = element;
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && isValid && !loading) {
                      handleSubmit(values);
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPasswordConfirmation(
                              !showPasswordConfirmation
                            );
                          }}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showPasswordConfirmation ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* end password confirmation input */}
                {/* end repeatedPassword input */}

                {/* submit button */}
                <Button
                  onClick={() => {
                    handleSubmit(values, resetForm);
                  }}
                  fullWidth
                  variant="contained"
                  size="large"
                  className={classes.button}
                  disabled={!isValid || loading}
                >
                  <Typography className={classes.buttonText}>Update</Typography>
                </Button>
                {/* end submit button */}
              </div>
            )}
          </Formik>
        </div>
      </MainFrame>
    </AuthTemplate>
  );
}

export default UpdatePassword;
