const settings = {
  awsAmplify: {
    Auth: {
      identityPoolId: "us-east-1:b0bb612d-08fc-41ba-a10c-45d03eb7a9d6",
      region: "us-east-1",
      userPoolId: "us-east-1_4vcI0N3vV",
      userPoolWebClientId: "5q1h64jcojrkb85ulsnhi6a59l",
      oauth: {
        domain: "auth.dev.samplifier.app",
        scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin",
        ],
        redirectSignIn: `${window.location.origin}/signin`,
        redirectSignOut: `${window.location.origin}/signin`,
        responseType: "code",
      },
    },
    API: {
      endpoints: [
        {
          name: "admin-api",
          endpoint: "https://api.dev.samplifier.app",
        },
        {
          name: "web-api",
          endpoint: "https://api.dev.samplifier.app",
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: "samplifier-backend-api-stetcalccalcbucket0f7d5dedd3b7813304ce",
        region: "us-east-1",
        customPrefix: {
          public: "",
        },
      },
    },
  },
  baseUrl: "https://www.dev.samplifier.app/",
};

export default settings;
