export const ADMIN_API_ENDPOINT = "admin-api";
export const COGNITO_MOCK_ENDPOINT = "cognito";
export const GROUP_API_ENDPOINT = "group-api";
export const SHORTCODE_API_ENDPOINT = "shortcode-api";
export const WEB_API_ENDPOINT = "web-api";

const awsAmplify = {
  ADMIN_API_ENDPOINT,
  COGNITO_MOCK_ENDPOINT,
  GROUP_API_ENDPOINT,
  SHORTCODE_API_ENDPOINT,
  WEB_API_ENDPOINT,
};

export default awsAmplify;
