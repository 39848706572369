import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ReactComponent as AdobePdfIcon } from "@assets/icons/adobe-pdf.svg";
import { ReactComponent as DownloadIcon } from "@assets/icons/download.svg";
import ConfirmationDialog from "@src/components/ConfirmationDialog";

import {
  // FormLabel,
  makeStyles,
  // TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  Card,
  Button,
  Chip,
} from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import MainFrame from "@components/MainFrame";
// import AuthenticationService from "@services/Authentication";
import AuthTemplate from "@templates/Auth";
import CustomizedInputBase from "@src/components/NumericInput";
import { Redirect, useHistory } from "react-router";
import { useSelector } from "react-redux";
import Calculation from "@src/services/Calculation";
import SamplifierLogo from "@assets/img/samplifierLogo_v2_72dpi.png";
import IndicinaLogo from "@assets/img/indicinaLogo.png";

const useStyles = makeStyles((theme) => ({
  frameForm: {
    boxSizing: "border-box",
    padding: "3rem",
    background: "#FFFFFF",
    width: "100%",
    minWidth: "450px",
    height: "100%",
    borderRadius: "8.327px",
    boxShadow: "0px 35px 33.308px -15px rgba(83, 129, 248, 0.24)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
    zIndex: "2",
  },
  noPrint: {
    "@media print": {
      display: "none",
    },
  },
  chipAudited: {
    borderRadius: "5px",
    backgroundColor: "#D7FFDB",
    color: "#0CC634",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    "& svg": {
      color: "#0CC634",
    },
  },
  chipNotAudited: {
    borderRadius: "5px",
    backgroundColor: "#ffdbd7",
    color: "#c6340c",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    "& svg": {
      color: "#c6340c",
    },
  },
  title: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "39px",
    letterSpacing: "0.06em",
    color: "#656565",
    marginBottom: "2.5rem",
  },
  subTitle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "34px",
    color: "#333333",
  },
  resultTable: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    "& th": {
      backgroundColor: "#F4F8F9",
      fontFamily: "Inter",
      fontWeight: "600",
      fontSize: "1rem",
      lineHeight: "141%",
      textTransform: "uppercase",
      letterSpacing: "0.1em",
      textAlign: "center",
    },
    "& td": {
      padding: "11px 16px",
      textAlign: "center",
      fontSize: "1rem",
    },
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    },
    "@media print": {
      borderLeft: "3px solid rgba(224, 224, 224, 1)",
      borderRight: "3px solid rgba(224, 224, 224, 1)",
      "& th": {
        fontWeight: "400",
        fontFamily: "Inter, sans-serif",
      },
    },
  },
  radioText: {
    "& $span:last-child": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "19px",
      color: "#333333",
    },
  },
  overSampleInput: {},
  card: {
    padding: "16px 0 16px 26px",
    width: "404px",
    marginBottom: "14px",
    backgroundColor: "#F4F8F9",
    "& p": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "20px",
      lineHeight: "141%",
      color: "#333333",
      letterSpacing: "0.06em",
    },
  },
  shadow: {
    boxShadow: "0px 0px 1px 1px rgb(0 0 0 / 14%)",
  },
  downloadPdf: {
    backgroundColor: "#0D4A63",
    color: "#FFFFFF",
  },
  downloadCsv: {
    backgroundColor: "rgba(13, 74, 99, 0.8)",
    color: "#FFFFFF",
  },
  calculateButton: {
    color: "#0D4A63",
  },
  resultOptions: {
    width: "430px",
    gap: "10px",
  },
  numericInput: {
    width: "150px",
    height: "30px",
    "& input": {
      height: "100%",
      padding: "0 5px",
      backgroundColor: "#FFF",
      textAlign: "center",
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0 0 15px 0",
  },
  titleInfo: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "28.2px",
    letterSpacing: "1px",
  },
  chipWrapper: {
    margin: "10px 0",
  },
  print: {
    display: "none",
    "@media print": {
      display: "block",
    },
  },
  headerPrint: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "6px solid rgba(13, 74, 99, 0.8)",
    marginTop: "5px",
  },
  textPrint: {
    margin: "10px",
  },
  titlePrint: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "39px",
    letterSpacing: "0.06em",
    color: "#333333",
    marginBottom: "1.2rem",
  },
  subTitlePrint: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "28px",
    color: "#333333",
  },
  tablePrint: {
    paddingBottom: "2rem",
  },
  footerWrapper: {
    position: "fixed",
    bottom: "0px",
  },
  footerPrint: {
    margin: "20px auto 0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
}));

function ResultsPage(props) {
  const classes = useStyles();
  const history = useHistory();

  const calculationResult = useSelector((state) => state.calculation.result);
  const [overSample, setOverSample] = useState({});
  const [confirmOverSamplePdf, setConfirmOverSamplePdf] = useState(false);
  const [confirmOverSampleCsv, setConfirmOverSampleCsv] = useState(false);
  const SPECIAL_PRODUCT_SNP_DUAL_ELIGIBLE = "SNP-Dual Eligible";
  const SPECIAL_PRODUCT_MMP_HMO_DEMONSTRATION = "MMP HMO Demonstration";
  const SPECIAL_PRODUCT_CMS = "CMS";
  const MAP_SPECIAL_PRODUCT_PRODUCT_LINE = new Map([
    [
      SPECIAL_PRODUCT_SNP_DUAL_ELIGIBLE.toLowerCase(),
      `SNP/MMP`,
    ],
    [
      SPECIAL_PRODUCT_MMP_HMO_DEMONSTRATION.toLowerCase(),
      `SNP/MMP`,
    ],
    [SPECIAL_PRODUCT_CMS.toLowerCase(), `MA`],
  ]);

  useEffect(() => {
    if (calculationResult) {
      const overSampleData = Object.keys(
        calculationResult.response.preProcessedMrsss
      ).map((key) => ({
        [key]: calculationResult.overSampleValue,
      }));
      const overSampleObject = Object.assign(...overSampleData);
      setOverSample(overSampleObject);
    }
  }, [calculationResult]);

  if (!calculationResult) {
    return <Redirect to="/configuration" />;
  }

  const data = Object.entries(calculationResult.response.preProcessedMrsss).map(
    ([key, value]) => ({
      measure: key,
      mrss: value,
    })
  );

  const fssCalculation = (measure, oversample) => {
    return Math.floor(Math.ceil(measure + measure * (oversample / 100)));
  };

  const handleClickDownloadPdf = async () => {
    let continueToDownload = true;

    data.forEach((row) => {
      if (overSample[row.measure] > 20) {
        continueToDownload = false;
        setConfirmOverSamplePdf(true);
      }
    });
    if (continueToDownload === true) {
      downloadPdf();
    }
  };

  const downloadPdf = () => {
    setConfirmOverSamplePdf(false);
    const fileId = calculationResult.fileKey;
    Calculation.saveOverSamples(fileId, overSample).then(() => window.print());
  };

  const handleClickDownloadCsv = () => {
    let continueToDownload = true;

    data.forEach((row) => {
      if (Number(overSample[row.measure]) > 20) {
        continueToDownload = false;
        setConfirmOverSampleCsv(true);
      }
    });
    if (continueToDownload === true) {
      downloadCsv();
    }
  };

  const downloadCsv = () => {
    setConfirmOverSampleCsv(false);

    const fileId = calculationResult.fileKey;
    Calculation.exportFileCsv(fileId, overSample)
      .then((response) => {
        console.log("response", response);
        const blob = new Blob([response], {
          type: "application/vnd.ms-excel",
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = `samplifier-${calculationResult.response.outputMetadata.productLine
          }-${calculationResult.measurementYear}-${new Date().toISOString().split("T")[0]
          }.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function renderTable(rows) {
    return (
      <TableContainer component={Paper} className={classes.shadow}>
        <Table
          aria-label="simple table"
          className={`${classes.resultTable} ${classes.shadow}`}
        >
          <TableHead>
            <TableRow>
              <TableCell>MEASURE</TableCell>
              <TableCell align="right" style={{ width: 100 }}>
                MRSS
              </TableCell>
              <TableCell align="right" style={{ width: 140 }}>
                OVERSAMPLE %
              </TableCell>
              <TableCell align="right" style={{ width: 100 }}>
                FSS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.measure}
                </TableCell>
                <TableCell align="right" style={{ width: 100 }}>
                  {row.mrss}
                </TableCell>
                <TableCell align="right" style={{ width: 140 }}>
                  <CustomizedInputBase
                    value={overSample[row.measure]}
                    className={classes.numericInput}
                    step={"1"}
                    min={0}
                    max={100}
                    onChange={(e) => {
                      setOverSample({
                        ...overSample,
                        [row.measure]: e.target.value,
                      });
                    }}
                  />
                </TableCell>
                <TableCell align="right" style={{ width: 100 }}>
                  {fssCalculation(row.mrss, overSample[row.measure])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const renderPrint = (rows) => {
    return (
      <div className={classes.print}>
        <div>
          <header className={classes.headerPrint}></header>
          <div className={classes.textPrint}>
            <Typography className={classes.titlePrint}>
              Hybrid Measure Sample Sizes for Measurement Year{" "}
              {parseInt(calculationResult.measurementYear) + 1}
            </Typography>
            <Typography className={classes.subTitlePrint}>
              {calculationResult.response.outputMetadata.organizationName}
            </Typography>
            <Typography className={classes.subTitlePrint}>
              {calculationResult.response.outputMetadata.reportingProduct}
            </Typography>
            <Typography className={classes.subTitlePrint}>
              {calculationResult.response.outputMetadata.productLine}
            </Typography>
            <Typography className={classes.subTitlePrint}>
              {MAP_SPECIAL_PRODUCT_PRODUCT_LINE.has(calculationResult.response.outputMetadata.specialProject.toLowerCase()) ? MAP_SPECIAL_PRODUCT_PRODUCT_LINE.get(calculationResult.response.outputMetadata.specialProject.toLowerCase()) : calculationResult.response.outputMetadata.specialProject}
            </Typography>
            <Typography className={classes.subTitlePrint}>
              Org {calculationResult.response.outputMetadata.organizationId} Sub{" "}
              {calculationResult.response.outputMetadata.submissionId}
            </Typography>
            <Typography className={classes.subTitlePrint}>
              {calculationResult.response.outputMetadata.audited
                ? "Audited"
                : "Not Audited"}
            </Typography>
          </div>
          <div className={classes.tablePrint}>
            <TableContainer component={Paper} className={classes.shadow}>
              <Table
                aria-label="simple table"
                className={`${classes.resultTable} ${classes.shadow}`}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 130 }}>MEASURE</TableCell>
                    <TableCell align="right" style={{ width: 100 }}>
                      MRSS
                    </TableCell>
                    <TableCell align="right" style={{ width: 150 }}>
                      OVERSAMPLE %
                    </TableCell>
                    <TableCell align="right" style={{ width: 100 }}>
                      FSS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.measure}
                      </TableCell>
                      <TableCell align="right" style={{ width: 100 }}>
                        {row.mrss}
                      </TableCell>
                      <TableCell align="right" style={{ width: 140 }}>
                        {overSample[row.measure]}
                      </TableCell>
                      <TableCell align="right" style={{ width: 100 }}>
                        {fssCalculation(row.mrss, overSample[row.measure])}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <footer className={classes.footerPrint}>
            <img
              src={IndicinaLogo}
              alt="Indicina"
              width="62.471px"
              height="45.049px"
            />

            <img
              src={SamplifierLogo}
              alt="Samplifier"
              width="166px"
              height="36.5px"
            />
          </footer>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderPrint(data)}
      <div className={classes.noPrint}>
        <AuthTemplate>
          <MainFrame>
            <div className={classes.frameForm}>
              <div className={classes.pageWrapper}>
                <Typography className={classes.title}>
                  Hybrid Measure Sample Sizes for Measurement Year{" "}
                  {parseInt(calculationResult.measurementYear) + 1}
                </Typography>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item>{renderTable(data)}</Grid>
                    <Grid item>
                      <Box
                        sx={{ display: "flex", flexDirection: "column" }}
                        className={classes.resultOptions}
                      >
                        <Card
                          className={`${classes.card} ${classes.shadow}`}
                          variant="outlined"
                        >
                          <div
                            className={
                              calculationResult.response.outputMetadata
                                .specialProject
                                ? ""
                                  ? calculationResult.response.outputMetadata
                                    .specialProject === "None"
                                  : ""
                                : classes.textWrapper
                            }
                          >
                            <Typography className={classes.titleInfo}>
                              {
                                calculationResult.response.outputMetadata
                                  .organizationName
                              }
                            </Typography>
                            <Typography className={classes.titleInfo}>
                              {
                                calculationResult.response.outputMetadata
                                  .productLine
                              }
                            </Typography>
                          </div>
                          <Typography className={classes.titleInfo}>
                            {MAP_SPECIAL_PRODUCT_PRODUCT_LINE.has(calculationResult.response.outputMetadata.specialProject.toLowerCase()) ? MAP_SPECIAL_PRODUCT_PRODUCT_LINE.get(calculationResult.response.outputMetadata.specialProject.toLowerCase()) : calculationResult.response.outputMetadata.specialProject}
                          </Typography>
                          <Typography className={classes.titleInfo}>
                            {
                              calculationResult.response.outputMetadata
                                .reportingProduct
                            }
                          </Typography>
                          <Typography className={classes.titleInfo}>
                            Org{" "}
                            {
                              calculationResult.response.outputMetadata
                                .organizationId
                            }{" "}
                            Sub{" "}
                            {
                              calculationResult.response.outputMetadata
                                .submissionId
                            }
                          </Typography>
                          {calculationResult.response.outputMetadata.audited ? (
                            <div className={classes.chipWrapper}>
                              <Chip
                                className={classes.chipAudited}
                                label="Audited"
                                icon={<CheckCircleOutline />}
                              />
                            </div>
                          ) : (
                            <div className={classes.chipWrapper}>
                              <Chip
                                className={classes.chipNotAudited}
                                label="Not Audited"
                                icon={<HighlightOffIcon />}
                              />
                            </div>
                          )}
                        </Card>
                        <Button
                          fullWidth
                          startIcon={<AdobePdfIcon />}
                          variant="contained"
                          size="large"
                          className={classes.downloadPdf}
                          onClick={() => handleClickDownloadPdf()}
                        >
                          Download PDF
                        </Button>
                        <Button
                          fullWidth
                          startIcon={<DownloadIcon />}
                          variant="contained"
                          size="large"
                          className={classes.downloadCsv}
                          onClick={() => handleClickDownloadCsv()}
                        >
                          Download CSV
                        </Button>
                        <Button
                          fullWidth
                          variant="outlined"
                          size="large"
                          className={classes.calculateButton}
                          onClick={() => history.push("/configuration")}
                        >
                          Calculate MRSS and FSS for another submission
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <ConfirmationDialog
                message={`Written approval from NCQA is required for all oversamples over 20%. You can submit your request with a rationale to the PCS `}
                open={confirmOverSamplePdf}
                agreeButtonLabel={"Continue"}
                disagreeButtonLabel={"Cancel"}
                onAgree={() => downloadPdf()}
                onDisagree={() => setConfirmOverSamplePdf(false)}
                needApproval={true}
              />
              <ConfirmationDialog
                message={`Written approval from NCQA is required for all oversamples over 20%. You can submit your request with a rationale to the PCS `}
                open={confirmOverSampleCsv}
                agreeButtonLabel={"Continue"}
                disagreeButtonLabel={"Cancel"}
                onAgree={() => downloadCsv()}
                onDisagree={() => setConfirmOverSampleCsv(false)}
                needApproval={true}
              />
            </div>
          </MainFrame>
        </AuthTemplate>
      </div>
    </>
  );
}

ResultsPage.propTypes = {
  breadcrumbs: PropTypes.array,
};

ResultsPage.defaultProps = {
  breadcrumbs: [],
};

export default ResultsPage;
