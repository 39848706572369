import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";

// import UserUtil from "@utils/user";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/PersonOutlineOutlined";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MainFrame from "@components/MainFrame";
import AuthTemplate from "@templates/Auth";
import { useDispatch, useSelector } from "react-redux";
import AuthenticationService from "@services/Authentication";
import { toast } from "react-toastify";
import UserService from "@services/User";
import { login } from "@src/store/modules/auth";

const useStyles = makeStyles((theme) => ({
  frameForm: {
    boxSizing: "border-box",
    padding: "32px",
    background: "#FFFFFF",
    width: "100%",
    minWidth: "450px",
    height: "100%",
    borderRadius: "8.327px",
    boxShadow: "0px 35px 33.308px -15px rgba(83, 129, 248, 0.24)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "2",
    "@media all and (min-width: 768px)": {
      padding: "10px 10px 32px",
    },
  },
  paper: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    "@media all and (min-width: 768px)": {
      width: "320px",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  title: {
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "40px",
    fontStyle: "normal",
    color: "#0D4A63",
    alignSelf: "center",
    marginBottom: "10px",
    marginTop: "20px",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    fontStyle: "normal",
    color: "#0D4A63",
    alignSelf: "center",
    marginBottom: "10px",
  },
  button: {
    backgroundColor: "#0D4A63",
    margin: theme.spacing(2, 0, 0),
  },
  buttonText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    color: "#FFFFFF",
  },
  icon: {
    marginRight: "10px",
  },
  updatePasswordLink: {
    fontFamily: "Open Sans",
    fontWeight: "bold",
    color: "#D53127",
    fontSize: "16px",
    lineHeight: "22px",
    margin: theme.spacing(2, 0, 0),
  },
  cancelAccountButton: {
    background: "rgba(142, 174, 255, 0.1)",
    margin: theme.spacing(2, 0, 0),
  },
  confirmCancelAccountButton: {
    background: "#0D4A63",
  },
  cancelButtonText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    color: "#0D4A63",
  },
}));

function ProfilePage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.cognitoUser);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const validationSchema = Yup.object().shape({
    login: Yup.string().required("Required"),
    firstName: Yup.string()
      .required("Required")
      .matches(/^[a-zA-z]+([\s][a-zA-Z]+)*$/, "Please enter only letters."),
    lastName: Yup.string()
      .required("Required")
      .matches(/^[a-zA-z]+([\s][a-zA-Z]+)*$/, "Please enter only letters."),
    companyName: Yup.string(),
  });

  const handleUpdateProfile = (values) => {
    AuthenticationService.updateProfile(
      user,
      values.firstName,
      values.lastName,
      values.companyName
    )
      .then(() => {
        dispatch(
          login({
            ...user,
            firstName: values.firstName,
            lastName: values.lastName,
            companyName: values.companyName,
          })
        );
        toast.success("Profile updated successfully!", { autoClose: 1000 });
      })
      .catch(() => {
        toast.error("Failed to update profile!");
      });
  };

  const handleDelete = useCallback(() => {
    setDeleting(true);
    UserService.deleteUser(user.username)
      .then(() => {
        setShowConfirm(false);
        setDeleting(false);
        AuthenticationService.logout();
        toast.success("Your account has been deleted!", {
          autoClose: 1000,
        });
      })
      .catch((err) => {
        toast.error("Failed to delete account!");
        setShowConfirm(false);
        setDeleting(false);
      });
  }, [user]);

  const handleClose = useCallback(() => {
    if (deleting) {
      return;
    }

    setShowConfirm(false);
  }, [deleting]);

  const confirmDelete = () => {
    return (
      <Dialog
        open={showConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            If you click “I am sure!”, your account profile will be deleted and
            cannot be restored.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.confirmCancelAccountButton}
            onClick={handleDelete}
            disabled={deleting}
          >
            <Typography className={classes.buttonText}>I am sure!</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {confirmDelete()}
      <AuthTemplate>
        <MainFrame justifyLogo="center">
          <div className={classes.frameForm}>
            <Formik
              initialValues={user}
              validationSchema={validationSchema}
              initialErrors={false}
            >
              {({
                errors,
                touched,
                isValid,
                values,
                handleChange,
                handleBlur,
              }) => (
                <div className={classes.paper}>
                  <Typography className={classes.title}>Profile</Typography>
                  {/* login input */}
                  <TextField
                    value={values.login}
                    helperText={touched.login ? errors.login : ""}
                    error={touched.login && Boolean(errors.login)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="login"
                    label="Business email address"
                    name="login"
                    disabled={true}
                    autoComplete="login"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* login input */}

                  {/* First name input */}
                  <TextField
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.firstName ? errors.firstName : ""}
                    error={touched.firstName && Boolean(errors.firstName)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="firstName"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* First name input */}

                  {/* Last Name */}
                  <TextField
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.lastName ? errors.lastName : ""}
                    error={touched.lastName && Boolean(errors.lastName)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lastName"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* Last Name */}

                  {/* Company Name */}
                  <TextField
                    value={values.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.companyName ? errors.companyName : ""}
                    error={touched.companyName && Boolean(errors.companyName)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="companyName"
                    label="Company Name"
                    name="companyName"
                    autoComplete="companyName"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* Company Name */}

                  {/* submit button */}
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    className={classes.button}
                    onClick={() => {
                      if (isValid) {
                        handleUpdateProfile(values);
                      }
                    }}
                    disabled={!isValid}
                  >
                    <Typography className={classes.buttonText}>
                      Update
                    </Typography>
                  </Button>
                  <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    className={classes.cancelAccountButton}
                    onClick={() => setShowConfirm(true)}
                  >
                    <Typography className={classes.cancelButtonText}>
                      Cancel Account
                    </Typography>
                  </Button>
                </div>
              )}
            </Formik>
            {!/^(google_|microsoft_)/i.test(user.username) && (
              <Link
                className={classes.updatePasswordLink}
                to="/update-password"
              >
                Click here to change your password
              </Link>
            )}
          </div>
        </MainFrame>
      </AuthTemplate>
    </>
  );
}

ProfilePage.propTypes = {
  breadcrumbs: PropTypes.array,
};

ProfilePage.defaultProps = {
  breadcrumbs: [],
};

export default ProfilePage;
