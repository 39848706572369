import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { CloudUpload as CloudUploadIcon } from "@material-ui/icons";
import { Button, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#0D4A63",
    marginTop: "4px",
  },
  dropAreaText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "18px",
  },
  buttonText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    color: "#FFFFFF",
  },
}));

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: "8.327px",
  border: "1px dashed #B7B7B7",
  backgroundColor: "#EEEDFF",
  color: "#666666",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  border: "1px dashed #2196f3",
};

const acceptStyle = {
  border: "1px dashed #00e676",
};

const rejectStyle = {
  border: "3px dashed red",
};

function UploadFileComponent(props) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: "application/xml, text/xml",
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    validator: (files) => {
      if (!props.isValid) {
        props.setInvalid(true);
        return "form-invalid";
      } else if (props.fileSelected) {
        toast.warn(
          "Before uploading a new file, delete the current XML file by clicking on the X next to the file name below.",
        );
        return "file-already-selected";
      }
    },
    onDragOver: (e) => {
      e.dataTransfer.effectAllowed = "none";
    },
    onDropRejected: (rejections) => {
      toast.error(rejections[0].errors[0].message, { autoClose: 1500 });
    },
    onDropAccepted: (files) => {
      props.onFilesAdded(files);
    },
  });

  const classes = useStyles();
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(props.baseStyle || {}),
    }),
    [isDragActive, isDragAccept, isDragReject, props.baseStyle]
  );

  return (
    <div className="container">
      <div
        {...getRootProps({
          style,
        })}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon />
        <Typography className={classes.dropAreaText}>
          {"Drag and drop files here, or "}
          <br />
          <br />
        </Typography>
        <Button
          variant="contained"
          disabled={props.disabled}
          className={classes.button}
          onClick={() => {
            if (props.isValid === true) return open();
            props.setInvalid(true);
          }}
        >
          <Typography className={classes.buttonText}>
            Select file to upload
          </Typography>
        </Button>
      </div>
    </div>
  );
}

export default UploadFileComponent;
