import * as React from "react";
import { InputBase, IconButton, makeStyles, Box } from "@material-ui/core";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  numberInput: {
    border: "1px solid #DDDDDD",
    boxSizing: "border-box",
    borderRadius: "2px",
    padding: "0 2px",
    "& > input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
    "& > input[type=number]": {
      "-moz-appearance": "textfield", // needed to remove default arrow spinner from mozilla
    },
    maxWidth: "60px",
    minWidth: "50px",
  },
  container: {
    textAlign: "center",
  },
  boldIcon: {
    stroke: "#828282",
  },
}));

export default function NumericInput(props) {
  const classes = useStyles();
  const input = React.useRef(null);

  function addInputVal(val) {
    const newVal = parseFloat(parseFloat(props.value) + val).toFixed(
      0
    );

    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).set;
    nativeInputValueSetter.call(input.current, newVal);

    var ev2 = new Event("input", { bubbles: true });
    input.current.dispatchEvent(ev2);
  }

  return (
    <Box
      className={`${classes.container} ${props.className}`}
      sx={{
        display: "flex",
      }}
    >
      <IconButton
        aria-label="menu"
        sx={{ p: 1, m: 1 }}
        onClick={() => {
          addInputVal(-Number.parseFloat(props.step));
        }}
      >
        <RemoveIcon className={classes.boldIcon} />
      </IconButton>
      <InputBase
        style={
          props.page === "Configuration"
            ? { flexGrow: 1, fontSize: "1.125rem" }
            : { flexGrow: 1 }
        }
        className={classes.numberInput}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        inputProps={{
          "aria-label": props.placeholder,
          type: "number",
          step: props.step,
          max: props.max,
          min: props.min,
          ref: input,
        }}
        onKeyPress={(event) => {
          if (!/[0-9]+$/.test(event.key)) {
            event.preventDefault();
          }
        }}
        value={props.value}
        onBlur={props.onBlur}
        onChange={(e) => {
          if (e.target.value <= props.max && e.target.value >= props.min)
            props.onChange(e);
        }}
      />
      <IconButton
        aria-label="search"
        sx={{ p: 1, m: 1 }}
        onClick={() => {
          addInputVal(Number.parseFloat(props.step));
        }}
      >
        <AddIcon className={classes.boldIcon} />
      </IconButton>
    </Box>
  );
}
