import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

import MainFooter from "@components/MainFooter";
import HeaderNav from "./HeaderNav";

const styles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    // position: "absolute",
    // width: "100vw",
    // height: "100vh",
  },
});
function MainFrame(props) {
  const classes = styles();
  return (
    <div className={classes.wrapper}>
      <HeaderNav header="loggedIn" />
      {props.children}
      <MainFooter justifyLogo={props.justifyLogo} />
    </div>
  );
}

MainFrame.propTypes = {
  children: PropTypes.node.isRequired,
  justifyLogo: PropTypes.string,
};

MainFrame.defaultProps = {
  children: null,
  justifyLogo: "space-between",
};

export default MainFrame;
