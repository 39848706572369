class User {
  static findAttribute(user, key, defaultValue = "") {
    for (let item of user["Attributes"]) {
      if (item["Name"] === key) {
        return item["Value"];
      }
    }
    return defaultValue;
  }
  static findUserAttribute(user, key, defaultValue = "") {
    for (let item of user["UserAttributes"]) {
      if (item["Name"] === key) {
        return item["Value"];
      }
    }
    return defaultValue;
  }

  static mapFromCognitoUser(cognitoUser) {
    return {
      login: cognitoUser.attributes.email,
      lastName: cognitoUser.attributes.family_name,
      firstName: cognitoUser.attributes.given_name,
      companyName: cognitoUser.attributes["custom:companyName"],
      username: cognitoUser.signInUserSession.accessToken.payload.username,
      sub: cognitoUser.signInUserSession.accessToken.payload.sub,
    };
  }

  static mapFromOAuth2User(oAuth2user) {
    return {
      login: oAuth2user.signInUserSession.idToken.payload["email"],
      lastName: oAuth2user.signInUserSession.idToken.payload["family_name"],
      firstName: oAuth2user.signInUserSession.idToken.payload["given_name"],
      companyName:
        oAuth2user.signInUserSession.idToken.payload["custom:companyName"],
      username: oAuth2user.signInUserSession.accessToken.payload.username,
      sub: oAuth2user.signInUserSession.idToken.payload.sub,
    };
  }
}

export default User;
