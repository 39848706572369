import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import IndicinaLogo from "@assets/img/indicinaLogo.svg";

const useStyles = makeStyles(() => ({
  footer: {
    position: "relative",
    bottom: "0px",
    left: "0px",
    margin: "30px auto 0 auto",
    width: "100%",
    zIndex: 997,
    padding: "5px",
  },
  footerMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "106px",
    margin: "0px",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
}));

function LoginFooter() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <div className={classes.footerMain}>
        <a href="https://www.indicina.com" target="_blank" rel="noreferrer">
          <img
            src={IndicinaLogo}
            alt="Indicina"
            width="124.95px"
            height="90.1px"
          />
        </a>
      </div>
    </div>
  );
}

export default LoginFooter;
