import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

import SamplifierLogo from "@assets/img/samplifierLogo_v2_72dpi.png";
import AuthenticationService from "@src/services/Authentication";
import { logout } from "@store/modules/auth";
import signOutIcon from "@assets/icons/signOut.svg";
import separator from "@assets/img/separator.svg";

const useStyles = makeStyles(() => ({
  bar: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100px",
    boxSizing: "border-box",
    zIndex: 0,
  },
  barMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "83px",
    margin: "0px",
    padding: "43px 30px 30px 31px",
    boxSizing: "border-box",
  },
  headerImg: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    marginTop: "20px",
    width: "332px",
    height: "73px",
    "@media all and (max-width: 680px)": {
      width: "300px",
      height: "66px",
      marginBottom: "10px",
    },
  },
  headerLoginOptionsWrapper: {
    display: "flex",
    alignSelf: "center",
    justifySelf: "flex-end",
  },
  headerLoginOptions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "-6px",
    flexWrap: "wrap",
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
  },
  signOutFlexWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    flexWrap: "no-wrap",
  },
  signOutIcon: {
    height: "18px",
    width: "18px",
    marginRight: "9px",
    marginTop: "-2px",
    verticalAlign: "middle",
  },
  span: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "25px",
    textAlign: "center",
    color: "#0D4A63",
    whiteSpace: "nowrap",
  },
  separator: {
    height: "18px",
    width: "18px",
    marginTop: "2px",
    marginLeft: "16px",
    marginRight: "17px",
    verticalAlign: "middle",
  },
}));

function HeaderNav({ header }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  function handleSignOut() {
    AuthenticationService.logout()
      .then(() => {
        dispatch(logout());
      })
      .catch(() => {
        dispatch(logout());
      });
  }

  return (
    <div className={classes.bar}>
      <div className={classes.barMain}>
        <Link to="/">
          <img
            className={classes.headerImg}
            src={SamplifierLogo}
            alt="Samplifier"
            margin="10px"
          />
        </Link>
        <div className={classes.headerLoginOptionsWrapper}>
          <div className={classes.headerLoginOptions}>
            {header === "auth" ? (
              <>
                <Link to="/signin" className={classes.link}>
                  <span className={classes.span}>SIGN IN</span>
                </Link>
                <img
                  className={classes.separator}
                  src={separator}
                  alt="Indicina"
                />
                <Link to="/signup" className={classes.link}>
                  <span className={classes.span}>SIGN UP</span>
                </Link>
              </>
            ) : (
              <>
                <div
                  className={`${classes.link} ${classes.signOutFlexWrapper}`}
                  onClick={() => handleSignOut()}
                >
                  <img
                    className={classes.signOutIcon}
                    src={signOutIcon}
                    alt="Indicina"
                  />
                  <span className={classes.span}> SIGN OUT</span>
                </div>
                <img
                  className={classes.separator}
                  src={separator}
                  alt="Indicina"
                />
                {location.pathname === "/profile" ? (
                  <div
                    className={classes.link}
                    onClick={() => history.push("/")}
                  >
                    <span className={classes.span}>HOME</span>
                  </div>
                ) : (
                  <Link to="/profile" className={classes.link}>
                    <span className={classes.span}>PROFILE</span>
                  </Link>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderNav;
