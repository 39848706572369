import { Auth } from "aws-amplify";

import Service from "@services/Service";
import { COGNITO_MOCK_ENDPOINT } from "@utils/awsAmplify";
import settings from "@settings/index";

class Authentication extends Service {
  static login(username, password) {
    if (process.env.REACT_APP_ENV === "development") {
      return Authentication.post(COGNITO_MOCK_ENDPOINT, "/signin", {
        body: {
          username,
          password,
        },
      });
    }

    return new Promise((resolve, reject) => {
      Auth.signIn({
        username,
        password,
      })
        .then((cognitoUser) => {
          if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
            reject({
              status: "ERROR",
              code: "NEW_PASSWORD_REQUIRED",
              message: "New password required",
              data: cognitoUser,
            });
          } else {
            resolve(cognitoUser);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static signUp(username, password, firstName, familyName, companyName) {
    return new Promise((resolve, reject) => {
      Auth.signUp({
        username,
        password,
        attributes: {
          given_name: firstName,
          family_name: familyName,
          "custom:companyName": companyName,
          // other custom attributes
        },
      })
        .then((cognitoUser) => {
          resolve(cognitoUser);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static googleLogin() {
    return new Promise((resolve, reject) => {
      Auth.federatedSignIn({
        provider: "Google",
      })
        .then((cognitoUser) => {
          resolve(cognitoUser);
        })
        .catch((err) => {
          reject(err);
          console.error(err);
        });
    });
  }

  static microsoftLogin() {
    return new Promise((resolve, reject) => {
      Auth.federatedSignIn({
        provider: "Microsoft",
      })
        .then((cognitoUser) => {
          resolve(cognitoUser);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static logout() {
    if (process.env.REACT_APP_ENV === "development") {
      return Authentication.post(COGNITO_MOCK_ENDPOINT, "/signout");
    }
    return Auth.signOut();
  }

  static logoutFederated(clientId) {
    return Authentication.get(
      `https://${settings.awsAmplify.Auth.oauth.domain}/logout?client_id=${clientId}&logout_uri=${settings.awsAmplify.Auth.oauth.redirectSignOut}`
    );
  }

  static completeNewPassword(user, password) {
    if (process.env.REACT_APP_ENV === "development") {
      return Authentication.post(
        COGNITO_MOCK_ENDPOINT,
        "/complete-new-password",
        {
          body: {
            user,
            password,
          },
        }
      );
    }

    return new Promise((resolve, reject) => {
      Auth.completeNewPassword(user, password)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject({
            status: "ERROR",
            code: "PASSWORD_REGISTRATION_ERROR",
            message: "Password registration error",
          });
        });
    });
  }

  static currentAuthenticatedUser() {
    if (process.env.REACT_APP_ENV === "development") {
      return Authentication.get(
        COGNITO_MOCK_ENDPOINT,
        "/current-authenticated-user"
      );
    }

    return Auth.currentAuthenticatedUser().catch(() => {
      return null;
    });
  }

  static checkAuthentication() {
    return Authentication.currentAuthenticatedUser().then((user) => {
      return !!user;
    });
  }

  static async forgotPassword(username) {
    if (process.env.REACT_APP_ENV === "development") {
      return Authentication.post(COGNITO_MOCK_ENDPOINT, "/forgot-password", {
        body: {
          username,
        },
      });
    }

    try {
      return Auth.forgotPassword(username);
    } catch (e) {
      return e;
    }
  }

  static async confirmSignUp(username, code) {
    try {
      return Auth.confirmSignUp(username, code);
    } catch (e) {
      return e;
    }
  }

  static async resendSignUp(username) {
    try {
      return Auth.resendSignUp(username);
    } catch (e) {
      return e;
    }
  }

  static confirmForgotPassword(login, code, password) {
    if (process.env.REACT_APP_ENV === "development") {
      return Authentication.post(
        COGNITO_MOCK_ENDPOINT,
        "/confirm-forgot-password",
        {
          body: {
            login,
            code,
            password,
          },
        }
      );
    }

    return Auth.forgotPasswordSubmit(login, code, password).catch(() => {
      return null;
    });
  }

  static updatePassword(currentPassword, newPassword) {
    if (process.env.REACT_APP_ENV === "development") {
      return Authentication.post(
        COGNITO_MOCK_ENDPOINT,
        "/confirm-forgot-password",
        {
          body: {
            currentPassword,
            newPassword,
          },
        }
      );
    }

    return new Promise((resolve, reject) => {
      Authentication.currentAuthenticatedUser()
        .then((cognitoUser) => {
          Auth.changePassword(cognitoUser, currentPassword, newPassword)
            .then(resolve)
            .catch((err) => reject(err));
        })
        .catch(() => {
          reject({
            status: "ERROR",
            code: "AUTHENTICATION_ERROR",
            message: "Authentication error",
          });
        });
    });
  }

  static updateProfile(currentUser, firstName, familyName, companyName) {
    if (process.env.REACT_APP_ENV === "development") {
      return Authentication.post(COGNITO_MOCK_ENDPOINT, "/update-profile", {
        body: {
          currentUser,
          firstName,
          familyName,
          companyName,
        },
      });
    }

    return new Promise((resolve, reject) => {
      Authentication.currentAuthenticatedUser()
        .then((cognitoUser) => {
          Auth.updateUserAttributes(cognitoUser, {
            family_name: familyName,
            given_name: firstName,
            "custom:companyName": companyName,
          })
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }
}

export default Authentication;
