import MainFrame from "@components/MainFrame";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  LinearProgress,
  // FormLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  // TextField,
  Typography,
} from "@material-ui/core";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import CalculationService from "@services/Calculation";
import ConfirmationDialog from "@src/components/ConfirmationDialog";
import NumericInput from "@src/components/NumericInput";
import UploadFileComponent from "@src/components/UploadFileComponent";
import { setResult } from "@src/store/modules/calculation";
import AuthTemplate from "@templates/Auth";
import { Auth, Storage } from "aws-amplify";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  frameForm: {
    boxSizing: "border-box",
    padding: "32px",
    background: "#FFFFFF",
    width: "100%",
    minWidth: "450px",
    height: "100%",
    borderRadius: "8.327px",
    boxShadow: "0px 35px 33.308px -15px rgba(83, 129, 248, 0.24)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
    zIndex: "2",
    "@media all and (min-width: 768px)": {
      padding: "10px",
    },
  },
  pageWrapper: {
    maxWidth: "100%",
    padding: "0 3rem 2rem 3rem",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "end",
    margin: "4rem 0 2.9rem 0",
    "@media all and (max-width: 680px)": {
      flexWrap: "nowrap",
    },
  },
  titleSecondLine: {
    display: "flex",
  },
  title: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1.75rem",
    lineHeight: "141%",
    letterSpacing: "0.06em",
    color: "#656565",
    margin: "0 1rem 0 2.4rem",
    paddingBottom: "4px",
  },
  yearSelector: {
    width: "fit-content",
    minWidth: "89px",
    height: "2.4rem",
    fontSize: "1.7rem",
    color: "#656565",
    fontWeight: "700",
    lineHeight: "2.4rem",
    paddingTop: "5px",
  },
  subTitle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "141%",
    color: "#656565",
    marginBottom: "10px",
  },
  flexRadioGroup: {
    display: "flex",
    width: "28rem",
    justifyContent: "flex-start",
    "& label:first-child": {
      marginRight: "24px",
    },
  },
  radioText: {
    "& span:last-child": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "1.125rem",
      lineHeight: "1.2rem",
      color: "#333333",
    },
  },
  overSample: { marginBottom: "2.5rem" },
  defaultOversample: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "141%",
    color: "#656565",
    marginTop: "2rem",
    marginBottom: "1.25rem",
  },
  baseStyle: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  activeStyle: {
    borderColor: "#2196f3",
  },
  acceptStyle: {
    borderColor: "#00e676",
  },
  rejectStyle: {
    borderColor: "#ff1744",
  },
  fileUploadStatus: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
  },
  button: {
    backgroundColor: "#0D4A63",
    marginTop: "4px",
  },
  numericInput: {
    height: "40px",
    width: "190px",
    "& input": {
      height: "100%",
      padding: "0 5px",
      backgroundColor: "#FFF",
      textAlign: "center",
    },
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  loading: {
    animation: "$spin 1s infinite ease",
  },
  fileList: {
    margin: "1.5rem 0 1.5rem 0",
  },
  dropAreaText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "18px",
  },
  buttonText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    color: "#FFFFFF",
  },
  missingFieldHelperText: {
    color: "#f44336",
    fontSize: "14px",
  },
  helpText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "10px",
    marginTop: "5px",
  },
}));

const CURRENT_YEAR = new Date().getFullYear();
const UPLOAD_STATUS_NONE = 0;
const UPLOAD_STATUS_UPLOADING = 1;
const UPLOAD_STATUS_VALIDATING = 2;
const UPLOAD_STATUS_VALIDATED = 3;
const UPLOAD_STATUS_ERROR = 4;
const PRODUCT_LINE_MEDICAID = "Medicaid";
const PRODUCT_LINE_COMMERCIAL = "Commercial";
const PRODUCT_LINE_MEDICARE = "Medicare";
const PRODUCT_LINE_EXCHANGE = "Exchange";
const SPECIAL_PRODUCT_SNP_DUAL_ELIGIBLE = "SNP-Dual Eligible";
const SPECIAL_PRODUCT_MMP_HMO_DEMONSTRATION = "MMP HMO Demonstration";
const SPECIAL_PRODUCT_CMS = "CMS";
const MAP_SPECIAL_PRODUCT_PRODUCT_LINE = new Map([
  [
    SPECIAL_PRODUCT_SNP_DUAL_ELIGIBLE.toLowerCase(),
    `${PRODUCT_LINE_MEDICARE} SNP/MMP`,
  ],
  [
    SPECIAL_PRODUCT_MMP_HMO_DEMONSTRATION.toLowerCase(),
    `${PRODUCT_LINE_MEDICARE} SNP/MMP`,
  ],
  [SPECIAL_PRODUCT_CMS.toLowerCase(), `${PRODUCT_LINE_MEDICARE} MA`],
]);
const productLines = [
  PRODUCT_LINE_COMMERCIAL,
  PRODUCT_LINE_EXCHANGE,
  PRODUCT_LINE_MEDICAID,
  `${PRODUCT_LINE_MEDICARE} MA`,
  `${PRODUCT_LINE_MEDICARE} SNP/MMP`,
];

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

function ConfigurationPage(props) {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [overSampleValue, setOverSampleValue] = React.useState("5");
  const classes = useStyles();
  const fileReader = useRef(new FileReader());
  const user = useSelector((state) => state.auth.cognitoUser);
  const [uploadStatus, setUploadStatus] = useState(UPLOAD_STATUS_NONE);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [confirmMedicaid, setConfirmMedicaid] = useState(false);
  const [confirmOverSample, setConfirmOverSample] = useState(false);
  const [productLine, setProductLine] = useState(null);
  const [measurementYear, setMeasurementYear] = useState("");
  const [currentInterval, setCurrentInterval] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [fileKey, setFileKey] = useState("");
  const [calculating, setCalculating] = useState(false);
  const [storagePutRequest, setStoragePutRequest] = useState(null);
  const [initialValues] = useState({
    measurementYear: (CURRENT_YEAR - 1).toString(),
    productLine: "",
  });
  const [confirmComercialOrMedicareMA, setConfirmComercialOrMedicareMA] =
    useState(false);
  const [confirmExchange, setConfirmExchange] = useState(false);
  const [confirmSecondMedicaid, setSecondConfirmMedicaid] = useState(false);
  const [medicaidFirstCombine, setMedicaidFirstCombine] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const uploadCompleted =
    uploadStatus === UPLOAD_STATUS_VALIDATED ||
    uploadStatus === UPLOAD_STATUS_ERROR;
  const tooltipMsg =
    uploadStatus === UPLOAD_STATUS_NONE
      ? "Select a file to upload"
      : uploadStatus === UPLOAD_STATUS_UPLOADING
      ? "We are uploading your file..."
      : uploadStatus === UPLOAD_STATUS_VALIDATING
      ? "We are validating your file..."
      : "";

  const validationSchema = Yup.object().shape({
    measurementYear: Yup.string()
      .min(4, "Invalid year")
      .max(4)
      .required("Please inform the year."),
    productLine: Yup.string()
      .oneOf(productLines)
      .required(
        "You have not selected a product line. Please select a product line."
      ),
  });

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  function renderOverSample() {
    return (
      <div className={classes.overSample}>
        <Typography className={classes.defaultOversample}>
          Default Oversample %
        </Typography>
        <NumericInput
          value={overSampleValue}
          className={classes.numericInput}
          step={"1"}
          min={0}
          max={100}
          page={"Configuration"}
          onChange={(e) => {
            setOverSampleValue(e.target.value);
          }}
        />
      </div>
    );
  }

  function handleFileSelected(files, values) {
    try {
      const file = files[0];
      fileReader.current.onload = (event) => {
        const content = event.target.result;
        const xmlParser = new DOMParser();
        const doc = xmlParser.parseFromString(content, "text/xml");
        const metadataEl = doc.getElementsByTagName("Metadata")[0];
        const fileSubmissionId =
          metadataEl?.getElementsByTagName("SubmissionId")[0]?.innerHTML;
        const fileOrganizationId =
          metadataEl?.getElementsByTagName("OrganizationId")[0]?.innerHTML;
        const fileMeasuremntYear =
          metadataEl?.getElementsByTagName("MeasurementYear")[0]?.innerHTML;
        const specialProduct =
          metadataEl?.getElementsByTagName("SpecialProject")[0]?.innerHTML;
        let fileProductLine =
          metadataEl?.getElementsByTagName("ProductLine")[0]?.innerHTML;

        if (
          fileProductLine?.toLowerCase() === PRODUCT_LINE_MEDICARE.toLowerCase()
        ) {
          fileProductLine = MAP_SPECIAL_PRODUCT_PRODUCT_LINE.get(
            specialProduct.toLowerCase()
          );
        }

        if (
          !fileSubmissionId ||
          !fileOrganizationId ||
          !fileProductLine ||
          !fileMeasuremntYear
        ) {
          return toast.error(
            "Could not import file. Some properties are missing or invalid!"
          );
        }

        const isProdLineDiff =
          fileProductLine.toLowerCase() !== values.productLine.toLowerCase();
        const isMeasurementYearDiff =
          fileMeasuremntYear !== values.measurementYear;

        let toastErrMsg =
          "The configuration settings do not match the uploaded XML data file:";

        if (isProdLineDiff || isMeasurementYearDiff) {
          const errors = [];
          if (isProdLineDiff) {
            errors.push(
              `Configuration product line is ‘${values.productLine}’, while data file product line is ‘${fileProductLine}’.`
            );
          }

          if (isMeasurementYearDiff) {
            errors.push(
              `Configuration measurement year is '${values.measurementYear}', while data file measurement year is '${fileMeasuremntYear}'.`
            );
          }

          return toast.error(
            <div>
              {toastErrMsg}

              {errors.map((err, idx) => (
                <p key={idx}>{err}</p>
              ))}
            </div>
          );
        }

        setSelectedFile(file);
        setProductLine(values.productLine);
        setMeasurementYear(values.measurementYear);
        setUploadStatus(UPLOAD_STATUS_UPLOADING);

        const fileKey = `${user.sub}/${
          values.measurementYear
        }-${fileOrganizationId}-${fileSubmissionId}-${Date.now()}.xml`;

        Auth.currentSession()
          .then((session) => {
            if (!session.isValid) {
              toast.error(
                "You user session has expired. Please Sign In again."
              );
              return Auth.signOut();
            }

            const storagePutRequest = Storage.put(`pending/${fileKey}`, file, {
              contentType: "text/xml",
              progressCallback(progress) {
                const calc = Math.floor(
                  (progress.loaded / progress.total) * 100
                );
                setUploadPercentage(calc);
              },
            });

            storagePutRequest
              .then(() => checkFileValidationStatus(fileKey))
              .catch((err) => {
                if (err.__CANCEL__) return;
                setUploadStatus(UPLOAD_STATUS_ERROR);
                setErrorMsg("Failed to upload file!");
                toast.error("Failed to upload file! Please try again.");
              });

            setStoragePutRequest(storagePutRequest);
          })
          .catch(() => {
            toast.error("You user session has expired. Please Sign In again.");
            return Auth.signOut();
          });
      };
      fileReader.current.onerror = (error) => {
        console.error(error);
        toast.error("Failed to upload file! Please try again.");
      };
      fileReader.current.readAsText(file);
    } catch (error) {
      toast.error("Failed to parse file!", { autoClose: 1500 });
    }
  }

  function handleRemoveFile() {
    setSelectedFile(null);
    setUploadStatus(UPLOAD_STATUS_NONE);
    setUploadPercentage(0);
    setFileKey("");

    currentInterval && clearInterval(currentInterval);
    storagePutRequest && Storage.cancel(storagePutRequest);
    setCurrentInterval(null);
  }

  function handleSubmit(skipOverSampleVerification) {
    console.log("onClick", skipOverSampleVerification);
    if (Number(overSampleValue) > 20 && !skipOverSampleVerification) {
      setConfirmOverSample(true);
    } else if (productLine.toLowerCase() === "medicaid") {
      console.log("entrou medcaid");
      setConfirmMedicaid(true);
      setConfirmOverSample(false);
    } else if (measurementYear !== "2020") {
      setConfirmOverSample(false);
      if (
        productLine.toLowerCase() === "commercial" ||
        productLine.toLowerCase() === "medicare ma"
      ) {
        setConfirmComercialOrMedicareMA(true);
      }
      if (productLine.toLowerCase() === "exchange") {
        setConfirmExchange(true);
      }
      if (productLine.toLowerCase() === "medicare snp/mmp") {
        requestCalc();
      }
    } else {
      requestCalc();
    }
  }

  function requestCalc(combine) {
    setConfirmOverSample(false);
    setConfirmMedicaid(false);
    setSecondConfirmMedicaid(false);
    setConfirmComercialOrMedicareMA(false);
    setConfirmExchange(false);

    setCalculating(true);
    CalculationService.calculate(fileKey, measurementYear, combine)
      .then((res) => {
        if (res.outputMetadata.audited === false) {
          toast.warn(
            "This submission has not been audited and cannot be used for sample size reduction of HEDIS measures that are to be submitted to NCQA. It is only acceptable to use these reductions for measures used for internal quality improvements purposes.",
            {
              autoClose: 12000,
            }
          );
        }
        dispatch(
          setResult({
            response: res,
            measurementYear,
            overSampleValue,
            fileKey,
          })
        );
        history.push("/results");
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => isMounted.current && setCalculating(false));
  }

  function checkFileValidationStatus(fileKey) {
    let status = UPLOAD_STATUS_VALIDATING;
    let retries = 0;
    setUploadStatus(status);
    const interval = setInterval(() => {
      if (status !== UPLOAD_STATUS_VALIDATING) return;
      CalculationService.getValidationStatus(fileKey)
        .then((res) => {
          if (res.status === 200) {
            setUploadStatus((status = UPLOAD_STATUS_VALIDATED));
            setFileKey(fileKey);
            clearInterval(interval);
            setCurrentInterval(null);
          } else {
            clearInterval(interval);
            setCurrentInterval(null);

            setUploadStatus((status = UPLOAD_STATUS_ERROR));
            toast.error(
              "Could not import file. Some properties are missing or invalid!"
            );
            setErrorMsg(
              "Could not import file. Some properties are missing or invalid!"
            );
          }
        })
        .catch((err) => {
          retries++;
          if (
            !(err.response?.status === 409 || err.response?.status === 404) ||
            retries > 30
          ) {
            clearInterval(interval);
            setCurrentInterval(null);
            setUploadStatus((status = UPLOAD_STATUS_ERROR));
            toast.error(
              "Could not import file. Some properties are missing or invalid!"
            );
            setErrorMsg(err.response?.data?.errorMessage);
          }
        });
    }, 1500);

    setCurrentInterval(interval);
  }

  const onConfirmSecondMedicaidModal = (medicaidSecondCombine) => {
    const medicaidCombine = [medicaidFirstCombine, medicaidSecondCombine];
    requestCalc(medicaidCombine);
  };

  return (
    <AuthTemplate>
      <MainFrame>
        <div className={classes.frameForm}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            initialErrors={false}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              validateForm,
              isValid,
            }) => (
              <div className={classes.pageWrapper}>
                <Box className={classes.titleBox}>
                  <Typography
                    className={classes.title}
                    style={{ marginLeft: "0px", marginRight: "0px" }}
                  >
                    Hybrid Measure Sample Size Calculator
                  </Typography>
                  <div className={classes.titleSecondLine}>
                    <Typography className={classes.title}>
                      Measurement Year of XML
                    </Typography>
                    <FormControl style={{ minWidth: 120 }}>
                      <Select
                        className={classes.yearSelector}
                        readOnly={selectedFile !== null}
                        name="measurementYear"
                        labelId="measurementYear-label"
                        id="measurementYear"
                        value={values.measurementYear}
                        label="Year"
                        onChange={handleChange}
                        required
                        error={
                          (invalid || touched.measurementYear) &&
                          Boolean(errors.measurementYear)
                        }
                      >
                        {/* DEFINE RANGE OF YEARS ON DROPDOWN */}
                        {range(CURRENT_YEAR - 3, CURRENT_YEAR - 1).map(
                          (year) => (
                            <MenuItem value={year.toString()} key={year}>
                              {year.toString()}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {invalid && errors.measurementYear && (
                        <FormHelperText
                          className={classes.missingFieldHelperText}
                        >
                          {errors.measurementYear}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </Box>

                {/* begin of radio group */}
                <FormControl component="fieldset">
                  <Typography className={classes.subTitle}>
                    Product Line
                  </Typography>
                  <RadioGroup
                    row
                    aria-label="products"
                    name="productLine-group"
                    value={values.productLine}
                    onChange={handleChange}
                    className={classes.flexRadioGroup}
                  >
                    {productLines.map((line) => (
                      <FormControlLabel
                        key={line}
                        disabled={selectedFile !== null}
                        name={"productLine"}
                        value={line}
                        control={
                          <Radio
                            style={{
                              color: "#0D4A63",
                            }}
                            checked={values.productLine === line}
                          />
                        }
                        label={line}
                        className={classes.radioText}
                      />
                    ))}
                  </RadioGroup>
                  {invalid && errors.productLine && (
                    <FormHelperText className={classes.missingFieldHelperText}>
                      {errors.productLine}
                    </FormHelperText>
                  )}
                </FormControl>
                {/* end of radio group */}

                {/* begin of oversample */}
                {renderOverSample()}
                {/* end of oversample */}
                <div>
                  <UploadFileComponent
                    baseStyle={{ height: "128px", justifyContent: "center" }}
                    onFilesAdded={(files) => {
                      handleFileSelected(files, values);
                    }}
                    fileSelected={!!selectedFile}
                    setInvalid={setInvalid}
                    isValid={isValid}
                    disabled={!!selectedFile}
                  />
                  <Collapse
                    onExited={() => handleRemoveFile()}
                    in={uploadStatus !== UPLOAD_STATUS_NONE}
                    className={classes.fileList}
                  >
                    <Box display="flex" style={{ width: "100%" }}>
                      {uploadStatus === UPLOAD_STATUS_VALIDATED ? (
                        <IconButton
                          disabled
                          aria-label="upload-success"
                          size="small"
                        >
                          <CheckCircleOutlineIcon
                            style={{ color: "#AFC93C", paddingRight: "8px" }}
                          />
                        </IconButton>
                      ) : uploadStatus === UPLOAD_STATUS_ERROR ? (
                        <IconButton
                          disabled
                          aria-label="upload-success"
                          size="small"
                        >
                          <HighlightOffIcon
                            style={{ color: "#FF0000", paddingRight: "8px" }}
                          />
                        </IconButton>
                      ) : (
                        ""
                      )}
                      <Typography
                        sx={{ flexGrow: 1 }}
                        className={classes.fileUploadStatus}
                      >
                        {selectedFile && selectedFile.name}
                      </Typography>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => setUploadStatus(UPLOAD_STATUS_NONE)}
                      >
                        <CloseIcon style={{ color: "#777777" }} />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{ width: "100%", marginTop: "5px" }}
                      style={{
                        backgroundColor:
                          uploadStatus === UPLOAD_STATUS_ERROR
                            ? "#FF0000"
                            : uploadStatus === UPLOAD_STATUS_VALIDATED
                            ? "#AFC93C"
                            : "#6CD1FC",
                        borderRadius: 5,
                      }}
                    >
                      <LinearProgress
                        color={uploadCompleted ? "inherit" : "primary"}
                        value={
                          uploadStatus === UPLOAD_STATUS_VALIDATING
                            ? 0
                            : uploadPercentage
                        }
                        variant={
                          uploadStatus === UPLOAD_STATUS_VALIDATING
                            ? "indeterminate"
                            : "determinate"
                        }
                        style={{
                          height: "7px",
                          borderRadius: 5,
                        }}
                      />
                    </Box>
                    <Typography className={classes.helpText}>
                      {uploadStatus === UPLOAD_STATUS_UPLOADING
                        ? `Uploading... ${uploadPercentage}%`
                        : uploadStatus === UPLOAD_STATUS_VALIDATING
                        ? `Validating your file...`
                        : uploadStatus === UPLOAD_STATUS_VALIDATED
                        ? "Upload completed"
                        : uploadStatus === UPLOAD_STATUS_ERROR
                        ? errorMsg
                        : ""}
                    </Typography>
                  </Collapse>
                </div>
                <Tooltip title={tooltipMsg} placement="top" arrow>
                  <span>
                    <Button
                      disabled={
                        uploadStatus !== UPLOAD_STATUS_VALIDATED || calculating
                      }
                      fullWidth
                      variant="contained"
                      size="large"
                      className={classes.button}
                      onClick={() => handleSubmit(false)}
                    >
                      <Typography className={classes.buttonText}>
                        {calculating && <CircularProgress size={14} />}{" "}
                        Calculate
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
              </div>
            )}
          </Formik>
          <ConfirmationDialog
            message={`Written approval from NCQA is required for all oversamples over 20%. You can submit your request with a rationale to the PCS `}
            open={confirmOverSample}
            agreeButtonLabel={"Continue"}
            disagreeButtonLabel={"Cancel"}
            onAgree={() => handleSubmit(true)}
            onDisagree={() => setConfirmOverSample(false)}
            needApproval={true}
          />

          <ConfirmationDialog
            message={
              "You selected Medicaid. Do you want to combine the CIS and LSC samples?"
            }
            open={confirmMedicaid && measurementYear === "2020"}
            agreeButtonLabel={"Yes"}
            disagreeButtonLabel={"No"}
            onAgree={() => requestCalc([["CIS", "LSC"]])}
            onDisagree={() => requestCalc("")}
            needApproval={false}
          />

          <ConfirmationDialog
            message={"Do you want to combine the BPD, EED, and HBD samples?"}
            open={confirmComercialOrMedicareMA}
            agreeButtonLabel={"Yes"}
            disagreeButtonLabel={"No"}
            onAgree={() => requestCalc([["BPD", "EED", "HBD"]])}
            onDisagree={() => requestCalc("")}
            needApproval={false}
          />

          <ConfirmationDialog
            message={"Do you want to combine the EED and HBD samples?"}
            open={confirmExchange}
            agreeButtonLabel={"Yes"}
            disagreeButtonLabel={"No"}
            onAgree={() => requestCalc([["EED", "HBD"]])}
            onDisagree={() => requestCalc("")}
            needApproval={false}
          />

          <ConfirmationDialog
            message={"Do you want to combine the BPD, EED, and HBD samples?"}
            open={confirmMedicaid && measurementYear !== "2020"}
            agreeButtonLabel={"Yes"}
            disagreeButtonLabel={"No"}
            onAgree={() => {
              setMedicaidFirstCombine(["BPD", "EED", "HBD"]);
              setConfirmMedicaid(false);
              setSecondConfirmMedicaid(true);
            }}
            onDisagree={() => {
              setMedicaidFirstCombine([]);
              setConfirmMedicaid(false);
              setSecondConfirmMedicaid(true);
            }}
            needApproval={false}
          />
          <ConfirmationDialog
            message={"Do you want to combine the CIS and LSC samples?"}
            open={confirmSecondMedicaid}
            agreeButtonLabel={"Yes"}
            disagreeButtonLabel={"No"}
            onAgree={() => onConfirmSecondMedicaidModal(["CIS", "LSC"])}
            onDisagree={() => requestCalc([medicaidFirstCombine])}
            needApproval={false}
          />
        </div>
      </MainFrame>
    </AuthTemplate>
  );
}

ConfigurationPage.defaultProps = {
  breadcrumbs: [],
};

export default ConfigurationPage;
