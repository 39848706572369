import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

const styles = makeStyles({
  auth: {
    position: "fixed",
    top: "0px",
    // top: "15vh",
    left: "0px",
    width: "100%",
    height: "100%",
    // height: "85vh",
    overflow: "auto",
  },
  authMain: {
    position: "relative",
    minWidth: "100%",
    minHeight: "100%",
    boxSizing: "border-box",
    padding: "85px 20px 10px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "radial-gradient(circle,#fff 0%,#daeaea 30%,#528fa8 100%)",
  },
  authContent: {},
});

function Auth(props) {
  const classes = styles();
  return (
    <main className={classes.auth}>
      <div className={classes.authMain}>
        <section className={classes.authContent}>{props.children}</section>
      </div>
    </main>
  );
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

Auth.defaultProps = {
  children: null,
};

export default Auth;
