import Service from "@services/Service";
import { ADMIN_API_ENDPOINT } from "@utils/awsAmplify";

class User extends Service {
  static getUser(username) {
    return User.get(ADMIN_API_ENDPOINT, "/user", {
      queryStringParameters: {
        username,
      },
    });
  }

  static getUsers() {
    return User.get(ADMIN_API_ENDPOINT, "/user");
  }

  static find(userName) {
    return User.get(ADMIN_API_ENDPOINT, `/user/${userName}`);
  }

  static updateUser(userName, data) {
    return User.put(ADMIN_API_ENDPOINT, `/user/${userName}`, { body: data });
  }

  static getUserGroups(userName) {
    return User.get(ADMIN_API_ENDPOINT, `/user/${userName}/groups`);
  }

  //usando no projeto
  static deleteUser(userName) {
    return User.del(ADMIN_API_ENDPOINT, `/user/${userName}`);
  }

  static resendInvitation(userName) {
    return User.post(ADMIN_API_ENDPOINT, `/user/${userName}/resend_invitation`);
  }

  static getGroups() {
    return User.get(ADMIN_API_ENDPOINT, "/groups");
  }

  static addUserToGroup(group, userName) {
    return User.put(ADMIN_API_ENDPOINT, `/groups/${group}/user/${userName}`);
  }

  static deleteUserFromGroup(group, userName) {
    return User.del(ADMIN_API_ENDPOINT, `/groups/${group}/user/${userName}`);
  }

  static createUser(group, data) {
    return User.post(ADMIN_API_ENDPOINT, `/groups/${group}/user`, {
      body: data,
    });
  }
}

export default User;
