const settings = {
  awsAmplify: {
    Auth: {
      identityPoolId: "us-east-1:3f16886b-6f5a-4bd6-9bc8-ed3c9f5dd709",
      region: "us-east-1",
      userPoolId: "us-east-1_ICYz6znC9",
      userPoolWebClientId: "r6ktm6mujluke2s6hrunk8mo0",
      oauth: {
        domain: "auth.staging.samplifier.app",
        scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin",
        ],
        redirectSignIn: `${window.location.origin}/signin`,
        redirectSignOut: `${window.location.origin}/signin`,
        responseType: "code",
      },
    },
    API: {
      endpoints: [
        {
          name: "admin-api",
          endpoint: "https://api.staging.samplifier.app",
        },
        {
          name: "web-api",
          endpoint: "https://api.staging.samplifier.app",
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: "samplifier-backend-api-stetcalccalcbucketa78f5d90a94856a7be0a",
        region: "us-east-1",
        customPrefix: {
          public: "",
        },
      },
    },
  },
  baseUrl: "https://www.staging.samplifier.app/",
};

export default settings;
