import React, { useEffect, useState } from "react";
import { Link, Route, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from "@material-ui/icons/PersonOutlineOutlined";
import LockIcon from "@material-ui/icons/LockOutlined";
import BusinessIcon from "@material-ui/icons/BusinessOutlined";
import MailIcon from "@material-ui/icons/MailOutlineOutlined";
import { InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import settings from "@settings/index";
import AuthFrame from "@components/AuthFrame";
import AuthenticationService from "@services/Authentication";
import { notifyTimeout, notifyDisabledUser } from "@store/modules/auth";
import AuthTemplate from "@templates/Auth";
import ForgotPassword from "@pages/ForgotPassword";
import PasswordCode from "@pages/PasswordCode";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    "@media all and (min-width: 768px)": {
      width: "320px",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#660099",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  forgotPassword: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  forgotPasswordLink: {
    textDecoration: "none",
    color: "#0D4A63",
  },
  forgotPasswordText: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    fontStyle: "normal",
  },
  title: {
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "40px",
    fontStyle: "normal",
    color: "#0D4A63",
    alignSelf: "center",
    marginBottom: "10px",
    marginTop: "20px",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    fontStyle: "normal",
    color: "#0D4A63",
    alignSelf: "center",
    marginBottom: "10px",
  },
  button: {
    backgroundColor: "#0D4A63",
    margin: theme.spacing(2, 0, 0),
  },
  buttonText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    color: "#FFFFFF",
  },
  dividerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0",
  },
  divider: {
    color: "#0D4A63",
    margin: "10px 0",
  },
  dividerText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    color: "#0D4A63",
  },
  socialButtonsWrapper: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginBottom: "10px",
  },
  socialButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: "150px",
    height: "40px",
    border: "0.876993px solid #03014C",
    borderRadius: "100px",
    background: "#fff",
  },
  icon: {
    marginRight: "10px",
  },
  disclosure: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "21px",
    fontStyle: "normal",
    color: "#424242",
  },
  disclosureLink: {
    textDecoration: "none",
    color: "#d84037",
  },
  signUp: {
    alignSelf: "flex-end",
    marginTop: "20px",
  },
}));

function Login(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  let emailInputRef = null;
  let firstNameInputRef = null;
  let lastNameInputRef = null;
  let companyNameInputRef = null;

  function executeLogin(values) {
    if (loading) {
      return;
    }
    setLoading(true);
    AuthenticationService.signUp(
      values.login,
      values.password,
      values.firstName,
      values.lastName,
      values.companyName
    )
      .then(() => {
        AuthenticationService.logout()
          .catch(() => {
            return false;
          })
          .then(() => {
            setLoading(false);
            history.push("/signin");
            toast.success(
              `We’ve sent an email to ${values.login} containing a link.`
            );
          });
      })
      .catch((error) => {
        if (error.code === "UserLambdaValidationException") {
          toast.warn(
            "There has been an error while processing your Sign Up Request. Please try again later. If you continue to experience difficulties, please contact mcampbell@indicina.com"
          );
        } else {
          toast.warn(error.message);
        }
        setLoading(false);
      });
  }

  const timeoutNotification = useSelector(
    (state) => state.auth.timeoutNotification
  );
  const disabledUserNotification = useSelector(
    (state) => state.auth.disabledUserNotification
  );

  useEffect(() => {
    function showSessionExpiredAlert() {
      toast.warn("Your session expired. Please sign in again.");
      dispatch(notifyTimeout(false));
    }

    function showDisabledUserAlert() {
      toast.warn("Access denied.");
      dispatch(notifyDisabledUser(false));
    }

    if (timeoutNotification) {
      showSessionExpiredAlert();
    }

    if (disabledUserNotification) {
      showDisabledUserAlert();
    }
  }, [timeoutNotification, disabledUserNotification, dispatch]);

  const initialValues = {
    login: "",
    firstName: "",
    lastName: "",
    companyName: "",
    password: "",
  };
  const validationSchema = Yup.object().shape({
    login: Yup.string().required("Required"),
    firstName: Yup.string()
      .required("Required")
      .matches(/^[a-zA-z]+([\s][a-zA-Z]+)*$/, "Please enter only letters."),
    lastName: Yup.string()
      .required("Required")
      .matches(/^[a-zA-z]+([\s][a-zA-Z]+)*$/, "Please enter only letters."),
    password: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/,
        "Required a minimum of 8 characters, including at least one capital letter, one number and one symbol."
      ),
  });

  return (
    <AuthTemplate>
      <AuthFrame>
        {/* <LoginHeader /> */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          isInitialValid={false}
        >
          {({ errors, touched, isValid, values, handleChange, handleBlur }) => (
            <div className={classes.paper}>
              <Typography className={classes.title}>
                Sign up to Samplifier
              </Typography>
              <Typography className={classes.subtitle}>
                Complete the form below to complete your registration
              </Typography>
              {/* login input */}
              <TextField
                value={values.login}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.login ? errors.login : ""}
                error={touched.login && Boolean(errors.login)}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id="login"
                label="Business email address"
                name="login"
                autoComplete="login"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && emailInputRef) {
                    emailInputRef.focus();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* end login input */}

              {/* first name input */}
              <TextField
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.firstName ? errors.firstName : ""}
                error={touched.firstName && Boolean(errors.firstName)}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id="firstName"
                label="First name"
                name="firstName"
                autoComplete="name"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && firstNameInputRef) {
                    firstNameInputRef.focus();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* first name input */}

              {/* last name input */}
              <TextField
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.lastName ? errors.lastName : ""}
                error={touched.lastName && Boolean(errors.lastName)}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id="lastName"
                label="Last name"
                name="lastName"
                autoComplete="family-name"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && lastNameInputRef) {
                    lastNameInputRef.focus();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* last name input */}

              {/* company name input */}
              <TextField
                value={values.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.companyName ? errors.companyName : ""}
                error={touched.companyName && Boolean(errors.companyName)}
                variant="outlined"
                margin="normal"
                fullWidth
                id="companyName"
                label="Company name"
                name="companyName"
                autoComplete="organization"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && companyNameInputRef) {
                    companyNameInputRef.focus();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* company name input */}

              {/* password input */}
              <TextField
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.password ? errors.password : ""}
                error={touched.password && Boolean(errors.password)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="password"
                inputRef={(element) => {
                  emailInputRef = element;
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && isValid && !loading) {
                    executeLogin(values);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* end password input */}

              {/* submit button */}
              <Button
                onClick={() => {
                  executeLogin(values);
                }}
                fullWidth
                variant="contained"
                size="large"
                className={classes.button}
                disabled={!isValid || loading}
              >
                <Typography className={classes.buttonText}>Sign up</Typography>
              </Button>
              {/* end submit button */}

              <Typography className={classes.disclosure}>
                By continuing, you agree to the Samplifier
                <a
                  className={classes.disclosureLink}
                  href={`${settings.baseUrl}files/docs/Samplifier Terms of Service.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  className={classes.disclosureLink}
                  href={`${settings.baseUrl}files/docs/Samplifier Privacy Policy.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </Typography>
            </div>
          )}
        </Formik>
        <div className={classes.signUp}>
          <Typography className={classes.disclosure}>
            Already have an account ?
            <Link className={classes.disclosureLink} to="/signin">
              {" "}
              Sign in
            </Link>
          </Typography>
        </div>
      </AuthFrame>

      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>

      <Route path="/change-password">
        <PasswordCode />
      </Route>
    </AuthTemplate>
  );
}

export default Login;
