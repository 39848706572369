import { configureStore } from "@reduxjs/toolkit";

import authReducer from "@store/modules/auth";
import exampleReducer from "@store/modules/example";
import calculation from "./modules/calculation";

export default configureStore({
  reducer: {
    auth: authReducer,
    calculation: calculation,
    example: exampleReducer,
  },
});
