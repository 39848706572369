import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import AuthFrame from "@components/AuthFrame";
import AuthenticationService from "@services/Authentication";
import { setCognitoUser } from "@store/modules/auth";
import AuthTemplate from "@templates/Auth";
import { passwordRule, confirmationPasswordRule } from "@utils/validation";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "100%",
    "@media all and (min-width: 768px)": {
      width: "320px",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#660099",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
}));

function PasswordRegistration(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  let passwordConfirmationInputRef = null;

  const cognitoUser = useSelector((state) => state.auth.cognitoUser);

  if (!cognitoUser) {
    redirectToLogin();
  }

  function redirectToLogin() {
    props.history.push("/signin");
  }

  function returnToLogin() {
    dispatch(setCognitoUser(null));
    props.history.push("/signin");
  }

  function register(values) {
    if (loading) {
      return;
    }

    setLoading(true);

    AuthenticationService.completeNewPassword(cognitoUser, values.password)
      .then(() => {
        setLoading(false);
        toast.success("Senha cadastrada!");
        returnToLogin();
      })
      .catch(() => {
        setLoading(false);
        toast.warn("Erro ao cadastrar senha!");
      });
  }

  const initialValues = {
    password: "",
    passwordConfirmation: "",
  };
  const validationSchema = Yup.object().shape({
    password: passwordRule(),
    passwordConfirmation: confirmationPasswordRule("password"),
  });

  return (
    <AuthTemplate>
      <AuthFrame banner="left">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          isInitialValid={false}
        >
          {({ errors, touched, isValid, values, handleChange, handleBlur }) => (
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Change your password
              </Typography>
              <Typography>
                Enter a new password using a minimum of 8 characters, including
                at least one capital letter, one number and one symbol.
              </Typography>
              {/* password input */}
              <TextField
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.password ? errors.password : ""}
                error={touched.password && Boolean(errors.password)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && passwordConfirmationInputRef) {
                    passwordConfirmationInputRef.focus();
                  }
                }}
              />
              {/* end password input */}
              {/* password confirmation input */}
              <TextField
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  touched.passwordConfirmation
                    ? errors.passwordConfirmation
                    : ""
                }
                error={
                  touched.passwordConfirmation &&
                  Boolean(errors.passwordConfirmation)
                }
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="passwordConfirmation"
                label="Confirm password"
                type="password"
                id="passwordConfirmation"
                inputRef={(element) => {
                  passwordConfirmationInputRef = element;
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && isValid && !loading) {
                    register(values);
                  }
                }}
              />
              {/* end password confirmation input */}
              {/* submit button */}
              <Button
                onClick={() => {
                  register(values);
                }}
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                disabled={!isValid || loading}
              >
                Submit
              </Button>
              {/* end submit button */}
            </div>
          )}
        </Formik>
      </AuthFrame>
    </AuthTemplate>
  );
}

export default PasswordRegistration;
