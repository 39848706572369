import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    cognitoUser: null,
    initialized: false,
    authenticated: false,
    timeoutNotification: false,
    disabledUserNotification: false,
  },
  reducers: {
    setCognitoUser(state, action) {
      state.cognitoUser = action.payload;
    },
    initialize(state) {
      state.initialized = true;
    },
    login(state, action) {
      state.cognitoUser = action.payload;
      state.authenticated = true;
    },
    logout(state) {
      state.authenticated = false;
    },
    notifyTimeout(state, action) {
      if (typeof action.payload === "boolean") {
        state.timeoutNotification = action.payload;
      } else {
        state.timeoutNotification = true;
      }
    },
    notifyDisabledUser(state, action) {
      if (typeof action.payload === "boolean") {
        state.disabledUserNotification = action.payload;
      } else {
        state.disabledUserNotification = true;
      }
    },
  },
});

export const {
  setCognitoUser,
  initialize,
  login,
  logout,
  notifyTimeout,
  notifyDisabledUser,
} = authSlice.actions;

export default authSlice.reducer;
