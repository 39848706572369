import { Auth, API } from "aws-amplify";

class Service {
  static methods = {
    get: "GET",
    post: "POST",
    put: "PUT",
    del: "DELETE",
  };

  static get(endpoint, path, options = null) {
    return Service.execute("get", endpoint, path, options);
  }

  static post(endpoint, path, options = null) {
    return Service.execute("post", endpoint, path, options);
  }

  static put(endpoint, path, options = null) {
    return Service.execute("put", endpoint, path, options);
  }

  static del(endpoint, path, options = null) {
    return Service.execute("del", endpoint, path, options);
  }

  static execute(action, endpoint, path, options = null) {
    if (process.env.REACT_APP_ENV === "development") {
      return Service.executeMock(action, endpoint, path, options);
    } else {
      return Service.executeAPI(action, endpoint, path, options);
    }
  }

  static executeAPI(action, endpoint, path, options = null) {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((currentSession) => {
          if (!currentSession.isValid()) {
            return Auth.signOut();
          }

          const token = currentSession.getIdToken().getJwtToken();
          const apiOptions = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            ...options,
          };
          API[action](endpoint, path, apiOptions)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        })
        .catch((error) => {
          return Auth.signOut();
        });
    });
  }

  static executeMock(action, endpoint, path, options = null) {
    let url = `/${endpoint}${path}`;
    if (options && options.queryStringParameters) {
      url +=
        "?" +
        Object.keys(options.queryStringParameters)
          .map((key) => `${key}=${options.queryStringParameters[key]}`)
          .join("&");
    }

    const request = new Request(url, {
      method: Service.methods[action],
      ...options,
    });

    return new Promise((resolve, reject) => {
      fetch(request).then((response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            resolve(data);
          } else {
            reject(data);
          }
        });
      });
    });
  }
}

export default Service;
