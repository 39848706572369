import { createSlice } from "@reduxjs/toolkit";

export const calculationSlice = createSlice({
  name: "calculation",
  initialState: {
    result: null,
  },
  reducers: {
    setResult: (state, action) => {
      state.result = { ...action.payload };
    },
  },
});

export const { setResult } = calculationSlice.actions;

export default calculationSlice.reducer;
