import React, { useEffect, useState } from "react";
import { Link, Route } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
// import Divider from "@mui/material/Divider";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import PersonIcon from "@material-ui/icons/PersonOutlineOutlined";
import LockIcon from "@material-ui/icons/LockOutlined";
import IconButton from "@material-ui/core/IconButton";
import { InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AuthFrame from "@components/AuthFrame";
import AuthenticationService from "@services/Authentication";
import { notifyTimeout, notifyDisabledUser } from "@store/modules/auth";
import AuthTemplate from "@templates/Auth";
import ForgotPassword from "@pages/ForgotPassword";
import PasswordCode from "@pages/PasswordCode";
import MicrosoftIcon from "@assets/icons/microsoftIcon.png";
import settings from "@settings/index";
import GoogleIcon from "@assets/icons/googleIcon.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    "@media all and (min-width: 768px)": {
      width: "320px",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#660099",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  forgotPassword: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  forgotPasswordLink: {
    textDecoration: "none",
    color: "#0D4A63",
  },
  forgotPasswordText: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    fontStyle: "normal",
  },
  title: {
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "40px",
    fontStyle: "normal",
    color: "#0D4A63",
    alignSelf: "center",
    marginBottom: "10px",
    marginTop: "20px",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    fontStyle: "normal",
    color: "#0D4A63",
    alignSelf: "center",
    marginBottom: "10px",
  },
  button: {
    backgroundColor: "#0D4A63",
    margin: theme.spacing(2, 0, 0),
  },
  buttonText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    color: "#FFFFFF",
  },
  dividerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0",
  },
  divider: {
    color: "#0D4A63",
    margin: "10px 0",
  },
  dividerText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    color: "#0D4A63",
  },
  socialButtonsWrapper: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginBottom: "10px",
  },
  socialButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: "150px",
    height: "40px",
    border: "0.876993px solid #03014C",
    borderRadius: "100px",
    background: "#fff",
    cursor: "pointer",
  },
  icon: {
    marginRight: "10px",
  },
  disclosure: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "21px",
    fontStyle: "normal",
    color: "#424242",
  },
  disclosureLink: {
    textDecoration: "none",
    color: "#d84037",
  },
  signUp: {
    alignSelf: "flex-end",
    marginTop: "20px",
  },
}));

function Login(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  let emailInputRef = null;

  function executeLogin(values) {
    if (loading) {
      return;
    }

    setLoading(true);
    AuthenticationService.login(values.login, values.password).catch(
      (error) => {
        if (error && error.code === "UserNotConfirmedException") {
          AuthenticationService.resendSignUp(values.login).then(() => {
            toast.warn(
              "Check your email and confirm your account by clicking on the link."
            );
          });
        } else {
          if (
            error.code === "UserNotFoundException" ||
            error.code === "NotAuthorizedException"
          ) {
            toast.warn(
              "Your email and password combination are not recognized. Please check your data entry. If you continue to experience difficulties, please contact mcampbell@indicina.com"
            );
          } else {
            toast.warn(error.message);
          }
        }
        setLoading(false);
      }
    );
  }

  const timeoutNotification = useSelector(
    (state) => state.auth.timeoutNotification
  );
  const disabledUserNotification = useSelector(
    (state) => state.auth.disabledUserNotification
  );

  useEffect(() => {
    function showSessionExpiredAlert() {
      toast.warn("Your session expired. Please sign in again.");
      dispatch(notifyTimeout(false));
    }

    function showDisabledUserAlert() {
      toast.warn("Access denied.");
      dispatch(notifyDisabledUser(false));
    }

    if (timeoutNotification) {
      showSessionExpiredAlert();
    }

    if (disabledUserNotification) {
      showDisabledUserAlert();
    }
  }, [timeoutNotification, disabledUserNotification, dispatch]);

  const initialValues = {
    login: "",
    password: "",
  };
  const validationSchema = Yup.object().shape({
    login: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  function handleGoogleLogin() {
    AuthenticationService.googleLogin();
  }

  function handleMicrosoftLogin() {
    AuthenticationService.microsoftLogin();
  }

  function socialButton() {
    return (
      <div className={classes.socialButtonsWrapper}>
        <button
          className={classes.socialButton}
          onClick={() => handleGoogleLogin()}
        >
          <img src={GoogleIcon} alt="google" className={classes.icon} />
          <Typography>Google</Typography>
        </button>
        <button
          className={classes.socialButton}
          onClick={() => handleMicrosoftLogin()}
        >
          <img src={MicrosoftIcon} alt="microsoft" className={classes.icon} />
          <Typography>Microsoft</Typography>
        </button>
      </div>
    );
  }

  return (
    <AuthTemplate>
      <AuthFrame>
        {/* <LoginHeader /> */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          initialErrors={false}
        >
          {({ errors, touched, isValid, values, handleChange, handleBlur }) => (
            <div className={classes.paper}>
              <Typography className={classes.title}>
                Sign in to Samplifier
              </Typography>
              <Typography className={classes.subtitle}>
                Enter your email and password
              </Typography>
              {/* login input */}
              <TextField
                value={values.login}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.login ? errors.login : ""}
                error={touched.login && Boolean(errors.login)}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id="login"
                label="Email address"
                name="login"
                autoComplete="login"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && emailInputRef) {
                    emailInputRef.focus();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* end login input */}
              {/* email input */}
              <TextField
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.password ? errors.password : ""}
                error={touched.password && Boolean(errors.password)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                inputRef={(element) => {
                  emailInputRef = element;
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && isValid && !loading) {
                    executeLogin(values);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* end email input */}
              <div className={classes.forgotPassword}>
                <Link
                  to="/forgot-password"
                  className={classes.forgotPasswordLink}
                >
                  <Typography className={classes.forgotPasswordText}>
                    Forgot password?
                  </Typography>
                </Link>
              </div>
              {/* submit button */}
              <Button
                onClick={() => {
                  executeLogin(values);
                }}
                fullWidth
                variant="contained"
                size="large"
                className={classes.button}
                disabled={!isValid || loading}
              >
                <Typography className={classes.buttonText}>SIGN IN</Typography>
              </Button>
              {/* end submit button */}
              <div className={classes.dividerWrapper}>
                <Divider className={classes.divider} />
                <Typography className={classes.dividerText}>
                  or sign in with
                </Typography>
                <Divider />
              </div>
              {socialButton()}
              <Typography className={classes.disclosure}>
                By continuing, you agree to the Samplifier
                <a
                  className={classes.disclosureLink}
                  href={`${settings.baseUrl}files/docs/Samplifier Terms of Service.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  className={classes.disclosureLink}
                  href={`${settings.baseUrl}files/docs/Samplifier Privacy Policy.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </Typography>
            </div>
          )}
        </Formik>
        <div className={classes.signUp}>
          <Typography className={classes.disclosure}>
            Need an account ?
            <Link className={classes.disclosureLink} to="/signup">
              {" "}
              Sign up
            </Link>
          </Typography>
        </div>
      </AuthFrame>

      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>

      <Route path="/change-password">
        <PasswordCode />
      </Route>
    </AuthTemplate>
  );
}

export default Login;
