import developmentSettings from "@settings/development.js";
import productionSettings from "@settings/production.js";
import preProductionSettings from "@settings/preProduction.js";
import qaSettings from "@settings/qa.js";

let settings;
switch (process.env.REACT_APP_ENV) {
  case "development":
    settings = developmentSettings;
    break;
  case "production":
    settings = productionSettings;
    break;
  case "preproduction":
    settings = preProductionSettings;
    break;
  case "qa":
    settings = qaSettings;
    break;
  default:
    throw Error("Invalid environment.");
}

export default settings;
