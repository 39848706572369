import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Amplify from "aws-amplify";
import ptBr from "date-fns/locale/pt-BR";
import { createServer } from "miragejs";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import mocks from "@mocks/index";
import App from "@src/App";
import settings from "@settings/index";
import reportWebVitals from "@src/reportWebVitals";
import store from "@store";

import "@assets/css/base.css";

if (process.env.REACT_APP_ENV === "development") {
  createServer(mocks);
} else {
  Amplify.configure(settings.awsAmplify);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <MuiPickersUtilsProvider locale={ptBr} utils={DateFnsUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
