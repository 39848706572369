import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

import LoginFooter from "@components/LoginFooter";
import HeaderNav from "./HeaderNav";

const styles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    // position: "absolute",
    // width: "100vw",
    // height: "100vh",
  },
  frameForm: {
    boxSizing: "border-box",
    padding: "32px",
    background: "#FFFFFF",
    width: "100%",
    minWidth: "450px",
    height: "100%",
    borderRadius: "8.327px",
    boxShadow: "0px 35px 33.308px -15px rgba(83, 129, 248, 0.24)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "2",
    "@media all and (min-width: 768px)": {
      padding: "10px",
    },
  },
});
function AuthFrame(props) {
  const classes = styles();
  return (
    <div className={classes.wrapper}>
      <HeaderNav header="auth" />
      <div className={classes.frameForm}>{props.children}</div>
      <LoginFooter />
    </div>
  );
}

AuthFrame.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthFrame.defaultProps = {
  children: null,
};

export default AuthFrame;
