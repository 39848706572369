import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import LockIcon from "@material-ui/icons/LockOutlined";
import { InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import UserUtil from "@utils/user";

import AuthFrame from "@components/AuthFrame";
import AuthenticationService from "@services/Authentication";
import { notifyTimeout, notifyDisabledUser, login } from "@store/modules/auth";
import AuthTemplate from "@templates/Auth";
import { confirmationPasswordRule } from "@src/utils/validation";
import { useQuery } from "@src/utils/useQuery";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    "@media all and (min-width: 768px)": {
      width: "320px",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#660099",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    backgroundColor: "#0D4A63",
    margin: theme.spacing(2, 0, 3),
  },
  buttonText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    color: "#FFFFFF",
  },
  login: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: theme.spacing(2),
  },
  loginLink: {
    textDecoration: "none",
    color: "rgb(0, 0, 0)",
  },
  title: {
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "26.5px",
    lineHeight: "40px",
    fontStyle: "normal",
    color: "#0D4A63",
    alignSelf: "center",
    marginBottom: "10px",
    marginTop: "20px",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    fontStyle: "normal",
    color: "#0D4A63",
    alignSelf: "center",
    marginBottom: "10px",
  },
}));

function Login(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [queryEmail, setQueryEmail] = useState("");
  const [queryCode, setQueryCode] = useState("");

  let passwordConfirmationInputRef = null;

  useEffect(() => {
    if (query.get("email")) {
      setQueryEmail(query.get("email").replace(/ /g, "+"));
    }
    if (query.get("code")) {
      setQueryCode(query.get("code"));
    }
  }, [query]);

  function handleSubmit(values) {
    if (loading) {
      return;
    }

    setLoading(true);

    AuthenticationService.confirmForgotPassword(
      queryEmail,
      queryCode,
      values.password
    )
      .then(() => {
        toast.success("Your password was changed. Signing you in.");
        AuthenticationService.login(queryEmail, values.password).then(
          (cognitoUser) => {
            dispatch(login(UserUtil.mapFromCognitoUser(cognitoUser)));
          }
        );
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  }

  const timeoutNotification = useSelector(
    (state) => state.auth.timeoutNotification
  );
  const disabledUserNotification = useSelector(
    (state) => state.auth.disabledUserNotification
  );

  useEffect(() => {
    function showSessionExpiredAlert() {
      toast.warn("Your session expired. Please sign in again.");
      dispatch(notifyTimeout(false));
    }

    function showDisabledUserAlert() {
      toast.warn("Access denied.");
      dispatch(notifyDisabledUser(false));
    }

    if (timeoutNotification) {
      showSessionExpiredAlert();
    }

    if (disabledUserNotification) {
      showDisabledUserAlert();
    }
  }, [timeoutNotification, disabledUserNotification, dispatch]);

  const initialValues = {
    password: "",
    passwordConfirmation: "",
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/,
        "Required a minimum of 8 characters, including at least one capital letter, one number and one symbol."
      ),
    passwordConfirmation: confirmationPasswordRule("password"),
  });

  return (
    <AuthTemplate>
      <AuthFrame>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          isInitialValid={false}
        >
          {({ errors, touched, isValid, values, handleChange, handleBlur }) => (
            <div className={classes.paper}>
              <Typography className={classes.title}>
                Change your password?
              </Typography>
              <Typography className={classes.subtitle}>
                Create a new password with at least 8 characters, including at
                least one upper case letter, one lower case letter, one number
                and one special character.
              </Typography>

              {/* password input */}
              <TextField
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.password ? errors.password : ""}
                error={touched.password && Boolean(errors.password)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="new-password"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && passwordConfirmationInputRef) {
                    passwordConfirmationInputRef.focus();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* end password input */}
              {/* password confirmation input */}
              <TextField
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  touched.passwordConfirmation
                    ? errors.passwordConfirmation
                    : ""
                }
                error={
                  touched.passwordConfirmation &&
                  Boolean(errors.passwordConfirmation)
                }
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="passwordConfirmation"
                label="Confirm password"
                type={showPasswordConfirmation ? "text" : "password"}
                autoComplete="new-password"
                inputRef={(element) => {
                  passwordConfirmationInputRef = element;
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && isValid && !loading) {
                    handleSubmit(values);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPasswordConfirmation(
                            !showPasswordConfirmation
                          );
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {showPasswordConfirmation ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* end password confirmation input */}
              {/* end repeatedPassword input */}

              {/* submit button */}
              <Button
                onClick={() => {
                  handleSubmit(values);
                }}
                fullWidth
                variant="contained"
                size="large"
                className={classes.button}
                disabled={!isValid || loading}
              >
                <Typography className={classes.buttonText}>
                  Change my password
                </Typography>
              </Button>
              {/* end submit button */}
            </div>
          )}
        </Formik>
      </AuthFrame>
    </AuthTemplate>
  );
}

export default Login;
