import Service from "@services/Service";
import { ADMIN_API_ENDPOINT } from "@utils/awsAmplify";

class Calculation extends Service {
  static getValidationStatus(fileKey) {
    return Calculation.get(ADMIN_API_ENDPOINT, `/calc/filestatus/${fileKey}`);
  }

  static calculate(fileKey, measurementYear, combine) {
    const data = {
      s3Key: fileKey,
      measurementYear,
      combine: combine,
    };

    return Calculation.post(
      ADMIN_API_ENDPOINT,
      `/calc/configuration/${fileKey}`,
      {
        body: data,
      }
    );
  }

  static exportFileCsv(fileId, oversamples) {
    return Calculation.post(ADMIN_API_ENDPOINT, `/calc/exportcsv/${fileId}`, {
      body: {
        oversamples,
      },
    });
  }

  static saveOverSamples(fileId, oversamples) {
    return Calculation.post(ADMIN_API_ENDPOINT, `/calc/oversamples/${fileId}`, {
      body: {
        oversamples,
      },
    });
  }
}

export default Calculation;
