import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ArrowBack } from "@material-ui/icons";
import MailIcon from "@material-ui/icons/MailOutlineOutlined";
import { InputAdornment } from "@material-ui/core";
import * as Yup from "yup";

import AuthFrame from "@components/AuthFrame";
import AuthTemplate from "@templates/Auth";
import AuthenticationService from "@services/Authentication";
import { notifyTimeout, notifyDisabledUser } from "@store/modules/auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    "@media all and (min-width: 768px)": {
      width: "320px",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#660099",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    backgroundColor: "#0D4A63",
    margin: theme.spacing(2, 0, 4),
  },
  buttonText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    color: "#FFFFFF",
  },
  code: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: theme.spacing(2),
  },
  codeLink: {
    textDecoration: "none",
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    fontStyle: "normal",
    color: "#0D4A63",
  },
  backMenu: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  backMenuLink: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    gridTemplateColumns: "1fr 3fr",
    textDecoration: "none",
    color: "#D53127",
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "21px",
    fontStyle: "normal",
  },
  title: {
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "27px",
    lineHeight: "40px",
    fontStyle: "normal",
    color: "#0D4A63",
    alignSelf: "center",
    marginBottom: "10px",
    marginTop: "20px",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    fontStyle: "normal",
    color: "#0D4A63",
    alignSelf: "center",
    marginBottom: "10px",
  },
}));

function Login(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  let loginInputRef = null;

  function handleSubmit(values) {
    if (loading) {
      return;
    }
    setLoading(true);
    AuthenticationService.forgotPassword(values.login)
      .then(() => {
        setLoading(false);
        toast.success(
          `We’ve sent an email to ${values.login} containing a link.`
        );
        history.push("/");
      })
      .catch((error) => {
        if (error && error.code === "UserNotFoundException") {
          toast.warn("We don't have an account for this email address.");
          setLoading(false);
        } else {
          toast.warn(error.message);
          setLoading(false);
        }
      });
  }

  const timeoutNotification = useSelector(
    (state) => state.auth.timeoutNotification
  );
  const disabledUserNotification = useSelector(
    (state) => state.auth.disabledUserNotification
  );

  useEffect(() => {
    function showSessionExpiredAlert() {
      toast.warn("Your session expired. Please sign in again.");
      dispatch(notifyTimeout(false));
    }

    function showDisabledUserAlert() {
      toast.warn("Access denied.");
      dispatch(notifyDisabledUser(false));
    }

    if (timeoutNotification) {
      showSessionExpiredAlert();
    }

    if (disabledUserNotification) {
      showDisabledUserAlert();
    }
  }, [timeoutNotification, disabledUserNotification, dispatch]);

  const initialValues = {
    login: "",
  };
  const validationSchema = Yup.object().shape({
    login: Yup.string().required("Required"),
  });

  return (
    <AuthTemplate>
      <AuthFrame>
        <div className={classes.backMenu}>
          <Link to="/signin" className={classes.backMenuLink}>
            <ArrowBack />
            Back to Sign in
          </Link>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          isInitialValid={false}
        >
          {({ errors, touched, isValid, values, handleChange, handleBlur }) => (
            <div className={classes.paper}>
              <Typography className={classes.title}>
                Forgot your password?
              </Typography>
              <Typography className={classes.subtitle}>
                Enter your email address below and we will send you an email
                with a link to reset your password.
              </Typography>
              {/* login input */}
              <TextField
                value={values.login}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.login ? errors.login : ""}
                error={touched.login && Boolean(errors.login)}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id="login"
                label="Email address"
                name="login"
                autoComplete="login"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && loginInputRef) {
                    loginInputRef.focus();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* end login input */}

              {/* submit button */}
              <Button
                onClick={() => {
                  handleSubmit(values);
                }}
                fullWidth
                variant="contained"
                size="large"
                className={classes.submit}
                disabled={!isValid || loading}
              >
                <Typography className={classes.buttonText}>
                  Reset my password
                </Typography>
              </Button>
              {/* end submit button */}
            </div>
          )}
        </Formik>
      </AuthFrame>
    </AuthTemplate>
  );
}

export default Login;
