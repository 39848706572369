import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import IndicinaLogo from "@assets/img/indicinaLogo.svg";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  footer: {
    margin: "30px auto 0 auto",
    width: "100%",
    zIndex: 997,
    padding: "5px",
  },
  footerMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: (props) => props.justifyLogo,

    width: "100%",
    height: "106px",
    margin: "0px",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
  help: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#000000",
    alignSelf: "flex-end",
  },
  helpLink: {
    textDecoration: "none",
    fontWeight: "bold",
    color: "#0D4A63",
  },
}));

function MainFooter(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.footer}>
      <div className={classes.footerMain}>
        {props.justifyLogo === "space-between" ? (
          <Typography className={classes.help}>
            Need any help?{" "}
            <a
              href="https://indicina.com/contact-us"
              className={classes.helpLink}
              target="_blank"
              rel="noreferrer"
            >
              Contact us
            </a>
          </Typography>
        ) : null}

        <a href="https://www.indicina.com" target="_blank" rel="noreferrer">
          <img
            src={IndicinaLogo}
            alt="Indicina"
            width="124.95px"
            height="90.1px"
          />
        </a>
      </div>
    </div>
  );
}

export default MainFooter;

MainFooter.propTypes = {
  justifyLogo: PropTypes.string,
};

MainFooter.defaultProps = {
  justifyLogo: "space-between",
};
