const settings = {
  awsAmplify: {
    Auth: {
      identityPoolId: "",
      region: "us-east-1",
      userPoolId: "us-east-1_aY2eKttJi",
      userPoolWebClientId: "4t0scqerhoia3sd0lhjmcpromp",
      oauth: {
        domain: "auth.dev.samplifier.app",
        scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin",
        ],
        redirectSignIn: `${window.location.origin}/signin`,
        redirectSignOut: `${window.location.origin}/signin`,
        responseType: "code",
      },
    },
    API: {
      endpoints: [
        {
          name: "admin-api",
          endpoint: "https://2zo2h7hb6j.execute-api.us-east-1.amazonaws.com/v1",
        },
        {
          name: "web-api",
          endpoint: "https://2zo2h7hb6j.execute-api.us-east-1.amazonaws.com/v1",
        },
      ],
    },
    Storage: {},
  },
  baseUrl: "https://www.dev.samplifier.app/",
};

export default settings;
